import { InputProps, SelectOption, SelectProps } from 'common/types/types';
import { ErrorMessage, useFormik, FormikProvider, FastField } from 'formik';
import {
  //Avatar,
  Button,
  Checkbox,
  CropImage,
  FormDescription,
  Input,
  Label,
  Loader,
  Modal,
  PhoneInput,
  Select,
} from 'legacy-components/componets';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  creditObligationDescription,
  creditObligationTypeOptions,
  educationEarnedOptions,
  employmentTypeOptions,
  personalAddressFormDescription,
  personalDetailsFormDescription,
  personalEducationDescription,
  personalEmploymentDescription,
  petsDescription,
  petTypeOptions,
  previousResidentsDescription,
  spouseDetailDescription,
  spouseEmploymentDescription
} from './config';

import { CustomDatePicker, MultiSelect } from 'legacy-components/fields/fields';
import { renterProfileValidation } from 'validations/renter/renter-profile.validation';
// import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { notification } from 'services/services';
import { PatternFormat } from 'react-number-format';
import { removeEmptyValueObj } from 'helpers/remove-empty-value-obj';
// import { FileValue } from '../renter-verification-details-form/types';
// import { UploadFileCard } from '../renter-verification-details-form/upload-file-card';
// import { fields } from './fields';
import { DocumentsDetailsFormValues, RentalPartner, RentalPartnerEmploymentHistory, RenterPersonalDetailsValues } from './types';
// import {
//   DocumentsFileType,
//   verificationDocumentsFileTypeToVerificationFormFieldKeyMap,
//   verificationDocumentsFormFieldKeyToVerificationFileTypeMap,
// } from './mappings';
// import { CheckCircleFilled } from '@ant-design/icons';
import { EditTwoTone } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Progress } from 'antd';
import { AxiosProgressEvent } from 'axios';
import { colors } from 'colors';
import AddOutlined from '@mui/icons-material/AddOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Divider, IconButton, Tooltip, Avatar } from '@mui/material';
import {
  phoneNumberDisabledButtonStyles,
  phoneNumberDisabledInputStyles,
} from 'common/styles/phoneNumberDisabledStyles';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';
import useDeleteProfileImage from 'hooks/profile/use-delete-renter-image';
import { useUserProfile, useUserProfileValidation } from 'hooks/query';
import { useVerifyEmail } from 'hooks/query/user/use-verify-email';
import { useUpdateCurrentUser, useUpdateCurrentUserPhone } from 'hooks/use-user-profile.hook';
import { ErrorValidationMessage } from 'legacy-components/error-validation-message/error-validation-message';
import { VerificationErrors } from 'legacy-pages/renter/schedule-tour/config';
import {
  // removeUserProfileImage,
  // removeVerificationFile,
  updateUserProfileImage,
} from 'services/api/auth-api.service';
import { Notification } from 'services/notification.service';
import { RenterPersonalDetailsFormAddressField } from './address-field';
import { EmailVerificationModal } from './email-verification-modal';
import { stringAsFloatNumber, stringAsIntegerNumber } from 'helpers/input-field.helper';
import ConsentAcknowledgmentModal from './ConsentAcknowledgment';
import Placeholder from './Placeholder';
import { ConsentAcknowledgmentType } from 'common/enums/enums';
import ProfileAcknowledgmentDialogContent from './ProfileAcknowledgmentDialogContent';
import { isPersonAdult, isPersonCanHaveDriverLicense } from 'helpers/person-age.helpers';



// export type RenterPersonalDetailsValues = Omit<UserDetailsDto, 'thumbnailUrl' | 'id'> & {
//   image: File | null;
//   driverLicense: FileValue;
//   passport: FileValue;
//   addressField: string;
//   companyAddressField: string;
//   educationAddressField: string;
// };

// type ConvictionDetails = {
//   felonyConviction: string | undefined;
//   felonyDeferredAdjudication: string | undefined;
//   bankruptcyDeclaration: string | undefined;
// };

// type SpouseDetails = {
//   fullName: string;
//   residentAddress: string;
//   phoneNumber: string;
//   dateOfBirth: string;
//   socialSecurityNumber: string;
//   driverLicense: string;
//   employerName: string;
//   employerAddress: string;
//   employmentDate: string;
//   monthlyIncome: number;
// };

// type RenterResidentialDetails = {
//   address: string;
//   city: string;
//   state: string;
//   zip: string;
//   periodMonths: number;
//   periodYears: number;
//   ownerFullName: string;
//   ownerEmail: string;
//   ownerPhoneNumber: string;
// };

// type CreditObligation = {
//   obligationType: string;
//   amount: number;
// };

// export type RenterPersonalDetailsValues = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   phoneNumber: string;
//   dateOfBirth: string;
//   // driversLicenseNumber: string;
//   // driversLicenseState: string;
//   // driversLicenseIssueDate: string;
//   // driversLicenseExpirationDate: string;
//   // socialSecurityNumber: string;
//   gra: string;
//   isSSNNumber: boolean;
//   // passportNumber: string;
//   // passportCountry: string;
//   // passportIssueDate: string;
//   // passportExpirationDate: string;
//   address: string;
//   city: string;
//   state: string;
//   zip: string;
//   residentPeriodMonths: number;
//   residentPeriodYears: number;
//   companyAddress: string;
//   companyCity: string;
//   companyState: string;
//   companyZip: string;
//   previousResidens: RenterResidentialDetails[];
//   educationAddress: string;
//   educationCity: string;
//   educationState: string;
//   educationZip: string;
//   jobTitle: string;
//   employmentType: string;
//   companyName: string;
//   employmentYears: string;
//   employmentMonths: string;
//   householdIncome: string;
//   retired: boolean;
//   selfEmployed: boolean;
//   institutionName: string;
//   educationEarned: string;

//   image: File | null;
//   // driverLicense: FileValue;
//   // passport: FileValue;
//   addressField: string;
//   companyAddressField: string;
//   educationAddressField: string;
//   spouseDetails: SpouseDetails[]; 
//   creditObligations: CreditObligation[];
//   convictions: ConvictionDetails;
//   petsCount: string;
//   petsTypes: string[];
//   petsWeight: string;
// };

const adultAge = 18;
const RenterPersonalDetailsForm = () => {
  const [isCropImageOpen, setIsCropImageOpen] = useState(false);
  const [isEmailValid, setEmailValidation] = useState<boolean>(true);
  const [dataUoadingPercent, setDataUoadingPercent] = useState<Record<string, number>>({
    driverLicense: 0,
    passport: 0,
    profileImage: 0,
  });
  const [isUpoadingProgressVisible, setIsUpoadingProgressVisible] = useState(false);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isConsentAcknowledgmentOpen, setConsentAcknowledgmentOpen] = useState(false);

  const formik = useFormik<RenterPersonalDetailsValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      image: null,
      phoneNumber: '',
      dateOfBirth: '',
      // driversLicenseNumber: '',
      // driversLicenseState: '',
      // driversLicenseIssueDate: '',
      // driversLicenseExpirationDate: '',
      // driverLicense: {},

      socialSecurityNumber: '',
      gra: 'yes',
      // isSSNNumber: false,
      // passportNumber: '',
      // passportCountry: '',
      // passportIssueDate: '',
      // passportExpirationDate: '',
      // passport: {},

      addressField: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      durationYears: '',
      durationMonths: '',

      // companyAddressField: '',
      // companyAddress: '',
      // companyCity: '',
      // companyState: '',
      // companyZip: '',

      residences: [],
      employments: [],

      rentalPartners: [{
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        phoneNumber1: '',
        phoneNumber2: '',
        socialSecurityNumber: '',
        driverLicense: '',
        addressLine: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        rentalPartnerEmployments: []
      }],  
      
      educationAddressField: '',
      educationAddress: '',
      educationCity: '',
      educationState: '',
      educationZip: '',

      // jobTitle: '',
      // employmentType: '',
      // companyName: '',
      // employmentYears: '',
      // employmentMonths: '',

      //householdIncome: '',
      //retired: false,
      //selfEmployed: false,
      institutionName: '',
      educationEarned: '',

      creditObligations: [],
      
      petsCount: '',
      petsTypes: [],
      petsWeight: '',

      isFelonyConviction: null, 
      isFelonyDeferredAdjudication: null, 
      isBankruptcyDeclaration: null,

      acknowledgment: {
        applicantFirstname: '',
        applicantLastname: '',
        applicantSignature: '',
        signedAt: '',
        isSigned: false,
        acknowledgmentType: ConsentAcknowledgmentType.PERSONAL_INFO
      }
    },
    onSubmit: async (values) => await handleFormSubmit(values),
    validationSchema: renterProfileValidation,
  });

  const { values, errors, touched, handleChange, setFieldValue, setTouched, 
    submitForm, setValues, isSubmitting, validateForm, /*,isValid*/ } = formik;

  const queryClient = useQueryClient();
  const { data: userProfile, isLoading: isUserProfileLoading } = useUserProfile();
  const { data: userProfileVerification } = useUserProfileValidation();
  const { mutateAsync: verifyEmail, isPending: isVerificationOfEmail } = useVerifyEmail({
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The email for verification has been sent to your inbox.');
    },
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'You could not confirm email');
    },
  });
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'One of these fields is invalid: First Name, Last Name, DoB.');
    },
  });
  // const { mutateAsync: updateUserProfile } = useUpdateUserProfile({
  //   onError: (e) => {
  //     const notification = new Notification();
  //     notification.error(e?.response?.data?.detail || 'User details is not updated');
  //   },
  // });
  const { mutateAsync: updateCurrentUserPhone } = useUpdateCurrentUserPhone({
    onError: (e) => {
      const notification = new Notification();
      notification.error(e?.response?.data?.detail || 'User phone is not updated');
    },
  });
  // const { data: verificationFiles, isLoading: isVerificationFilesLoading } = useVerificationFiles();
  const [imageUrl, setImageUrl] = useState('');
  // const pageLoading = isUserProfileLoading || isVerificationFilesLoading;
  const pageLoading = isUserProfileLoading;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const updateDataUoadingPercent = (key: string, progress: AxiosProgressEvent) => {
    setDataUoadingPercent((prevState) => {
      return {
        ...prevState,
        [key]: progress.total ? Math.floor((progress.loaded * 100) / progress.total) : 0,
      };
    });
  };

  const totalDataUoadingPercent = useMemo<number>(() => {
    const values = Object.values<number>(dataUoadingPercent);
    return Math.floor(values.reduce((accumulator: number, value: number) => accumulator + value, 0) / values.length);
  }, [dataUoadingPercent]);

  const getCommonInputProps = (
    name: any,
    // name: keyof Omit<
    //   RenterPersonalDetailsValues,
    //   // | 'isSSNNumber'
    //   | 'retired'
    //   | 'selfEmployed'
    //   | 'driversLicenseIssueDate'
    //   | 'driversLicenseExpirationDate'
    //   | 'passportIssueDate'
    //   | 'passportExpirationDate'
    // >,
    placeholder: string,
  ): InputProps => {
    return {
      name,
      placeholder,
      // @ts-ignore TODO: FIX IGNORE
      value: values[name],
      onChange: handleChange,
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const hasValidationIssue = (fieldName: string): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const getSelectProps = (
    name: any,//keyof Pick<RenterPersonalDetailsValues, 'employmentType' | 'educationEarned'>,
    options: SelectOption[],
    placeholder: string,
  ): SelectProps => {
    return {
      name,
      options,
      placeholder,
      value: values[name],
      onChange: (value: any) => setFieldValue(name, value),
      selectStyle: { singleValue: { paddingLeft: 4 }, placeholder: { paddingLeft: 4 } },
    };
  };

  const formatAddress = (value: { address: string; city: string; state: string; zip: string }) => {
    return [value.address, value.city, value.state, value.zip].filter(Boolean).join(', ');
  };

  const handleFormSubmit = async (values: RenterPersonalDetailsValues) => {
    // const selectedFiles = {
    //   passport: values.passport,
    //   driverLicense: values.driverLicense,
    // };

    // const filesToUpload = Object.entries(selectedFiles).filter(([, value]) => value.file);

    // setDataUoadingPercent(() => {
    //   const filesToUploadPercentList = filesToUpload.reduce((acc, [key]) => {
    //     acc[key] = 0;
    //     return acc;
    //   }, {} as Record<string, number>);

    //   if (values.image) {
    //     filesToUploadPercentList.profileImage = 0;
    //   }

    //   return {
    //     ...filesToUploadPercentList,
    //   };
    // });

    await Promise.all([
      updateCurrentUser(values),
      // updateUserProfile(values),
      updateCurrentUserPhone({ phoneNumber: values.phoneNumber }),
    ]);
    // if (values.image) {
    //   setIsUpoadingProgressVisible(true);
    //   await updateUserProfileImage(values.image, (progressEvent) => {
    //     updateDataUoadingPercent('profileImage', progressEvent);
    //   });
    // }
    // if (!values.image && !imageUrl) {
    //   await removeUserProfileImage();
    // }

    // await Promise.all(
    //   filesToUpload.map(([name, { file }]) => {
    //     setIsUpoadingProgressVisible(true);
    //     const type =
    //       verificationDocumentsFormFieldKeyToVerificationFileTypeMap[name as keyof DocumentsDetailsFormValues];
    //     return uploadVerificationFile({
    //       type,
    //       file: file as File,
    //       onUploadProgress(progressEvent) {
    //         updateDataUoadingPercent(name, progressEvent);
    //       },
    //     });
    //   }),
    // );

    // const filesToRemove = Object.entries(selectedFiles).filter(([, value]) => !!value.id && !value.file && !value.url);
    // await Promise.all(filesToRemove.map(([, file]) => removeVerificationFile(file.id as string)));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
    notification.success('Thank you for updating your profile. Your changes are now saved.');
    setIsUpoadingProgressVisible(false);
  };

  const { mutateAsync: deleteProfileImage, isPending: isLoadingRemovingImg } = useDeleteProfileImage();
  const removeImage = async () => {
    await deleteProfileImage();
    setFieldValue('image', null);
    setImageUrl('');
  };

  const addImage = async (image: File) => {
    setIsUpoadingProgressVisible(true);
    await updateUserProfileImage(image, (progressEvent) => {
      updateDataUoadingPercent('profileImage', progressEvent);
    });
    setFieldValue('image', image);
    setImageUrl(URL.createObjectURL(image));
    await queryClient.invalidateQueries({ queryKey: ['user'] });
  };

  const handleCloseModal = () => {
    setIsCropImageOpen(false);
  };

  const handleCalendarChange = (fieldName: keyof RenterPersonalDetailsValues | string , date: Date | null) => {
    setFieldValue(fieldName, date?.toDateString()).then(() => {
      setTouched({ ...touched, [fieldName]: true }, true);
    });
  };

  // useEffect(() => {
  //   if (!verificationFiles) return;
  //   verificationFiles.forEach((file: any) => {
  //     const fieldValue: FileValue = { url: file.fileUrl, id: file.id };

  //     const type = file.type as DocumentsFileType;
  //     const field = verificationDocumentsFileTypeToVerificationFormFieldKeyMap[type];

  //     setFieldValue(field, fieldValue);
  //   });
  // }, [verificationFiles]);

  useLayoutEffect(() => {
    if (!userProfile) return;
    const userProfileFiltered = removeEmptyValueObj(userProfile) as RenterPersonalDetailsValues;
   
    const residences = userProfileFiltered.residences ?? [];
    const employments = userProfileFiltered.employments ?? [];

    residences.forEach((element, index) => {
      element.addressLine = formatAddress({
        address: element.address,
        state: element.state,
        city: element.city,
        zip: element.zipCode
      });
    });

    employments.forEach((element, index) => {
      element.addressLine = formatAddress({
        address: element.address,
        state: element.state,
        city: element.city,
        zip: element.zipCode
      });
    });

    userProfileFiltered?.rentalPartners.forEach(element => {
      element.addressLine = formatAddress({
        address: element.address,
        state: element.state,
        city: element.city,
        zip: element.zipCode
      });

      element.rentalPartnerEmployments?.forEach(employment => {
        employment.addressLine = formatAddress({
          address: employment.address,
          state: employment.state,
          city: employment.city,
          zip: employment.zipCode
        });
      })
    })

    // if(!userProfileFiltered.acknowledgment) {
    //   userProfileFiltered.acknowledgment = {
    //     applicantFirstname: '',
    //     applicantLastname: '',
    //     applicantSignature: '',
    //     signedAt: ''
    //   };
    // }

    setValues({
      ...values,
      ...userProfileFiltered,
      addressField: formatAddress({
        address: userProfileFiltered.address,
        state: userProfileFiltered.state,
        city: userProfileFiltered.city,
        zip: userProfileFiltered.zipCode,
      }),
      educationAddressField: formatAddress({
        address: userProfileFiltered.educationAddress,
        state: userProfileFiltered.educationState,
        city: userProfileFiltered.educationCity,
        zip: userProfileFiltered.educationZip,
      }),
      // companyAddressField: formatAddress({
      //   address: userProfileFiltered.companyAddress,
      //   state: userProfileFiltered.companyState,
      //   city: userProfileFiltered.companyCity,
      //   zip: userProfileFiltered.companyZip,
      // }),
      image: null,
    });
    setImageUrl(userProfile.thumbnailUrl);
  }, [userProfile]);

  const isEmailHasChanged = userProfile?.email && values["email"] && userProfile?.email !== values["email"];

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  useEffect(() => {
    validateForm();
  }, [values.addressField, values.educationAddressField, /*values.companyAddressField*/]);

  useEffect(() => {
    if (userProfileVerification?.errors && !userProfileVerification?.isProfileValid) {
      const isEmailNotVerified = userProfileVerification.errors.includes(VerificationErrors.ConfirmEmail);
      isEmailNotVerified ? setEmailValidation(false) : setEmailValidation(true);
    }
  }, [userProfileVerification]);

  // const updateFileCard = async (file: File, name: keyof DocumentsDetailsFormValues) => {
  //   setFieldValue(name, { ...values[name], url: URL.createObjectURL(file), file });
  // };

  // const deleteFileCard = async (name: keyof DocumentsDetailsFormValues) => {
  //   setFieldValue(name, { id: values[name].id });
  // };

  // const driversLicenseSection = () => {
  //   const driverFile = fields.at(1)!;

  //   return (
  //     <div className='flex flex-col gap-4'>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Drivers License Number'}>
  //             <Input {...getCommonInputProps('driversLicenseNumber', '***1412313PS')} />
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Drivers License State'}>
  //             <Select {...getSelectProps('driversLicenseState', statesSelectOptions, 'State')} />
  //           </Label>
  //         </div>
  //       </div>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Drivers License Issue Date'}>
  //             <CustomDatePicker
  //               name='driversLicenseIssueDate'
  //               value={values.driversLicenseIssueDate}
  //               onChange={(date) => handleCalendarChange('driversLicenseIssueDate', date)}
  //               maxDate={new Date()}
  //               minDate={values.dateOfBirth ?? null}
  //               invalid={Boolean(errors.driversLicenseIssueDate && touched.driversLicenseIssueDate)}
  //             />
  //             {showError('driversLicenseIssueDate') && (
  //               <p className='text-xs text-error py-1'>{errors.driversLicenseIssueDate}</p>
  //             )}
  //           </Label>
  //         </div>

  //         <div className='w-full'>
  //           <Label label={'Drivers License Expiration Date'}>
  //             <CustomDatePicker
  //               name='driversLicenseExpirationDate'
  //               value={values.driversLicenseExpirationDate}
  //               onChange={(date) => handleCalendarChange('driversLicenseExpirationDate', date)}
  //               minDate={new Date()}
  //               invalid={Boolean(errors.driversLicenseExpirationDate && touched.driversLicenseExpirationDate)}
  //             />
  //             {showError('driversLicenseExpirationDate') && (
  //               <p className='text-xs text-error py-1'>{errors.driversLicenseExpirationDate}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>

  //       <UploadFileCard
  //         type={driverFile.type}
  //         title={driverFile.title}
  //         format={driverFile.format}
  //         onChange={async (file) => {
  //           updateFileCard(file, driverFile.name);
  //         }}
  //         name={driverFile.name}
  //         fileValue={values[driverFile.name]}
  //         error={errors[driverFile.name]?.file}
  //         onDelete={async () => {
  //           deleteFileCard(driverFile.name);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  // const passportSection = () => {
  //   const passportFile = fields.at(0)!;
  //   return (
  //     <div className='flex flex-col gap-4'>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Passport Number'}>
  //             <Input {...getCommonInputProps('passportNumber', 'Passport Number')} />
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Passport Country'}>
  //             <Input {...getCommonInputProps('passportCountry', 'Passport Country')} />
  //           </Label>
  //         </div>
  //       </div>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Passport Issue Date'}>
  //             <CustomDatePicker
  //               name='passportIssueDate'
  //               value={values.passportIssueDate}
  //               onChange={(date) => handleCalendarChange('passportIssueDate', date)}
  //               maxDate={new Date()}
  //               minDate={values.dateOfBirth ?? null}
  //               invalid={Boolean(errors.passportIssueDate && touched.passportIssueDate)}
  //             />
  //             {showError('passportIssueDate') && <p className='text-xs text-error py-1'>{errors.passportIssueDate}</p>}
  //           </Label>
  //         </div>
  //         <div className='w-full'>
  //           <Label label={'Passport Expiration Date'}>
  //             <CustomDatePicker
  //               name='passportExpirationDate'
  //               placeholder='MM-DD-YYYY'
  //               value={values.passportExpirationDate}
  //               minDate={new Date()}
  //               onChange={(date) => handleCalendarChange('passportExpirationDate', date)}
  //               invalid={Boolean(errors.passportExpirationDate && touched.passportExpirationDate)}
  //             />
  //             {showError('passportExpirationDate') && (
  //               <p className='text-xs text-error py-1'>{errors.passportExpirationDate}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>
  //       <UploadFileCard
  //         type={passportFile.type}
  //         title={passportFile.title}
  //         format={passportFile.format}
  //         onChange={(file) => {
  //           updateFileCard(file, passportFile.name);
  //         }}
  //         name={passportFile.name}
  //         fileValue={values[passportFile.name]}
  //         error={errors[passportFile.name]?.file}
  //         onDelete={async () => {
  //           deleteFileCard(passportFile.name);
  //         }}
  //       />
  //     </div>
  //   );
  // };

  // const ssnSection = () => {
  //   return (
  //     <>
  //       <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
  //         <div className='w-full'>
  //           <Label label={'Social Security Number'}>
  //             <PatternFormat
  //               format='###-##-####'
  //               name='socialSecurityNumber'
  //               placeholder='111-11-****'
  //               value={values['socialSecurityNumber']}
  //               onChange={(e) => {
  //                 const numValue = e.target.value.replaceAll('-', '').replaceAll('_', '');
  //                 setFieldValue('socialSecurityNumber', numValue).then(() => {
  //                   setTouched({ socialSecurityNumber: true }, true);
  //                 });
  //               }}
  //               invalid={Boolean(errors['socialSecurityNumber'] && touched['socialSecurityNumber'])}
  //               customInput={Input}
  //               valueIsNumericString
  //               mask='_'
  //             />
  //             {showError('socialSecurityNumber') && (
  //               <p className='text-xs text-error py-1'>{errors.socialSecurityNumber}</p>
  //             )}
  //           </Label>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  // const [activeTab, setActiveTab] = useState(1);
  // const handleTabClick = (tabNumber: number) => {
  //   setActiveTab(tabNumber);
  // };

  // const renderTabContent = () => {
  //   switch (activeTab) {
  //     case 1:
  //       return driversLicenseSection();
  //     case 2:
  //       return passportSection();
  //     case 3:
  //       return ssnSection();
  //     default:
  //       return <></>;
  //   }
  // };

  // function changeInvalidDocumentTab(obj: any) {
  //   for (let key in obj) {
  //     // driver - any field related to driversLicense
  //     if (key.includes('driver')) {
  //       setActiveTab(1);
  //       return;
  //     }
  //     // passport - any field related to passport data
  //     if (key.includes('passport')) {
  //       setActiveTab(2);
  //       return;
  //     }
  //     if (key.includes('socialSecurityNumber')) {
  //       setActiveTab(3);
  //       return;
  //     }
  //   }
  // }

  // function isFieldInvalid(nameOfValue: string) {
  //   for (let key in errors) {
  //     if (key.includes(nameOfValue)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // const getTabColor = (isTabActive: boolean, validCondition: boolean, nameOfValue: string) => {
  //   if (isTabActive) {
  //     if (!isValid && isFieldInvalid(nameOfValue)) {
  //       return 'red';
  //     }

  //     if (validCondition) {
  //       return 'green';
  //     }

  //     return 'primary';
  //   }

  //   if (isFieldInvalid(nameOfValue)) {
  //     return 'red-outline';
  //   }

  //   return 'primary-outline';
  // };

  // const renderTabs = () => {
  //   const determineDriverLicenseClass = () => {
  //     const isTabActive = activeTab === 1;
  //     const hasDriverLicense = values['driverLicense']?.url;
  //     const hasRequiredValues =
  //       values.driversLicenseExpirationDate &&
  //       values.driversLicenseIssueDate &&
  //       values.driversLicenseNumber &&
  //       values.driversLicenseState;
  //     return getTabColor(isTabActive, Boolean(hasDriverLicense && hasRequiredValues), 'driver');
  //   };

  //   const determinePassportClass = () => {
  //     const isTabActive = activeTab === 2;
  //     const hasDriverLicense = values['passport']?.url;
  //     const hasRequiredValues =
  //       values.passportExpirationDate && values.passportIssueDate && values.passportCountry && values.passportNumber;
  //     return getTabColor(isTabActive, Boolean(hasDriverLicense && hasRequiredValues), 'passport');
  //   };

  //   const driverTabTheme = determineDriverLicenseClass();
  //   const passportTabTheme = determinePassportClass();
  //   const ssnTabTheme = getTabColor(activeTab === 3, false, 'socialSecurityNumber');
  //   const icon = <CheckCircleFilled className={'text-white  text-[20px]'} />;

  //   return (
  //     <>
  //       <div className='flex flex-col gap-3'>
  //         <FormDescription {...documentFormDescription} />
  //         <div className='flex gap-3 flex-wrap'>
  //           <Button
  //             onClick={() => handleTabClick(1)}
  //             label={'Drivers License'}
  //             theme={driverTabTheme}
  //             startIcon={driverTabTheme === 'green' ? icon : null}
  //           />
  //           <Button
  //             onClick={() => handleTabClick(2)}
  //             label={'Passport Number'}
  //             theme={passportTabTheme}
  //             startIcon={passportTabTheme === 'green' ? icon : null}
  //           />
  //           <Button onClick={() => handleTabClick(3)} label={'SSN'} theme={ssnTabTheme} />
  //         </div>
  //         <div className='tab-content'>{renderTabContent()}</div>
  //       </div>
  //     </>
  //   );
  // };
  
  // Handle period-scope numbers 
  function normilizePeriod(value: string | undefined): string | undefined{
    
    if(typeof value !== 'string')  
      return undefined;

    const parsedValue = parseFloat(value);

    if(isNaN(parsedValue))
      return undefined;

    if(parsedValue < 0)
      return '0';
  
    return value;
  }

  // Handle Conviction Statement option changed
  const handleConvictionStatementChanged = (conviction, value) => {
    values[conviction] = value;
    setValues({ ...values });
  }

  // Handle adding/removing element of Previous Residence section
  const handleAddResidenceHistory = () => {
    let residences = values.residences;
    
    residences.push({
      addressLine: '', 
      address: '', 
      city: '', 
      state: '', 
      zipCode: '',
      landlordName: '', 
      landlordEmail: '',
      phoneNumber: '',
      durationMonths: 0, 
      durationYears: 0
    });
    
    setValues({...values, residences});
  }

  const handleRemoveResidenceElement = (element, index) => {
    let residences = values.residences;

    if(residences.length > 0)
      residences.splice(index, 1);

    setValues({...values, residences});
  }

  // Handle adding/removing element of Previous Residence section
  const handleAddEmploymentElement = () => {
    let employments = values.employments;
    
    employments.push({
      jobTitle: '',
      employmentType: '',
      companyName: '',
      durationYears:'',
      durationMonths: '',
      householdIncome: '',
      addressLine: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      retired: false,
      selfEmployed: false
    });
    
    setValues({...values, employments});
  }
  
  const handleRemoveEmploymentElement = (element, index) => {
    let employments = values.employments;

    if(employments.length > 0)
      employments.splice(index, 1);

    setValues({...values, employments});
  }

  const handleAddPartner = () => {
    let rentalPartners = values.rentalPartners;    
 
    rentalPartners.push({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        email: '',
        phoneNumber1: '',
        phoneNumber2: '',
        socialSecurityNumber: '',
        driverLicense: '',
        addressLine: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        rentalPartnerEmployments: []
    });
    
    setValues({...values, rentalPartners});
  }

  const handleRemovePartner = (partnerIndex: number) => {
    let rentalPartners = values.rentalPartners;

    if(rentalPartners.length > 0)
      rentalPartners.splice(partnerIndex, 1);

    setValues({...values, rentalPartners});
  }

  const handleAddPartnerEmployment = (partnerIndex: number) => {
    let rentalPartnerEmployments = values.rentalPartners[partnerIndex]?.rentalPartnerEmployments ?? [];
    rentalPartnerEmployments.push(
      {
        employerName: '',
        addressLine: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        employedAt: '',  
        monthlyIncome: 0
      }
    );

    setValues({...values, rentalPartners: values.rentalPartners.map((x, index) => 
      index === partnerIndex ? { ...x, rentalPartnerEmployments: [...rentalPartnerEmployments] } : x)});
  }

  const handleRemovePartnerEmployment = (partnerIndex: number, partnerEmploymentIndex: number) => {
    let rentalPartnerEmployments = values.rentalPartners[partnerIndex]?.rentalPartnerEmployments ?? [];

    if(rentalPartnerEmployments.length > 0)
      rentalPartnerEmployments.splice(partnerEmploymentIndex, 1);

    setValues({...values, rentalPartners: values.rentalPartners.map((x, index) => 
      index === partnerIndex ? { ...x, rentalPartnerEmployments: [...rentalPartnerEmployments] } : x)});
  }

  // Handle adding/removing Credit Obligation element
  const handleAddCreditObligationElement = () => {
    let creditObligations = values.creditObligations;    
 
    creditObligations.push({obligationType: '', amount: 0 });
    
    setValues({...values, creditObligations});
  }

  const handleRemoveCreditObligationElement = (element, index) => {
    let creditObligations = values.creditObligations;

    if(creditObligations.length > 0)
      creditObligations.splice(index, 1);

    setValues({...values, creditObligations});
  }

  const handleOpenConsentAcknowledgment = () => {
    if(!isConsentAcknowledgmentOpen)
      setConsentAcknowledgmentOpen(!isConsentAcknowledgmentOpen);
  }

  const handleCloseConsentAcknowledgment = () => {
    if(isConsentAcknowledgmentOpen)
      setConsentAcknowledgmentOpen(!isConsentAcknowledgmentOpen);
  }

  const handleAcceptConsentAcknowledgment = (value) => {

    if(isConsentAcknowledgmentOpen) {
      setConsentAcknowledgmentOpen(!isConsentAcknowledgmentOpen);
    }
    
    setFieldValue('acknowledgment', {
      ...value, 
      applicantFirstname: values.firstName, 
      applicantLastname: values.lastName,
      acknowledgmentType: ConsentAcknowledgmentType.PERSONAL_INFO
    });
    
  }

  const isCollectionButtonDisabled = (collectionName: string) => {
    return (values[collectionName] !== null && values[collectionName].length >= 5);
  }

  const isAddPartnerEmploymentButtonDisabled = (rentalPartner: RentalPartner) => {
    return rentalPartner?.rentalPartnerEmployments?.length >= 5;
  }

  const isConsentAcknowledgmentDisabled = (): boolean => {
    return (values.acknowledgment?.isSigned === true);
  }

  const avatarFallback = (): string => {
    let fname = '';
    let lname = '';
    
    if(values.firstName && values.firstName.length >= 1) {
      fname = Array.from(values.firstName)[0];
    }

    if(values.lastName && values.lastName.length >= 1) {
      lname = Array.from(values.lastName)[0];
    }

    return fname.toLocaleUpperCase()+lname.toLocaleUpperCase();
  }

  const getAgeInfo = (date: string) => {
    const ageInfo = isPersonAdult(date) ? 'Adult': 'Child';
    return (<span className="text-xs font-bold pl-2 text-trueGray" style={{opacity: 0.6}}>{ageInfo}</span>);
  }

  /***
   ******************************************************************
   * COMPONENT RENDERING 
   ******************************************************************
  */
  

  return (
    <div className='flex flex-col gap-0 md:gap-9 mb-5 flex-1'>
      <Modal isOpen={isCropImageOpen} onClose={handleCloseModal} styles={{ content: { borderRadius: 15 } }}>
        <CropImage title={'Edit Photo'} onClose={handleCloseModal} onCrop={addImage} />
      </Modal>

      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await removeImage();
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete your profile photo ?'
        isLoadingSubmitBtn={isLoadingRemovingImg}
      />

      <ConsentAcknowledgmentModal 
          isOpen={isConsentAcknowledgmentOpen}
          onAccept={(value) => handleAcceptConsentAcknowledgment(value)}
          acknowledgment={values.acknowledgment}
          onClose={handleCloseConsentAcknowledgment}
          dialogContent={<ProfileAcknowledgmentDialogContent/>}
      />

      <div className='personal-details w-full flex flex-col gap-10 px-4 md:px-[34px] py-4 md:py-6 shadow-white-xl'>
        <FormDescription {...personalDetailsFormDescription} />
        {pageLoading ? (
          <Loader />
        ) : (
          <FormikProvider value={formik}>
            <div className='personal-details-form'>
              <div className='flex flex-col gap-[18px]'>
                <Label label={'Profile photo'}>
                  <div className={'flex justify-start gap-5'}>
                    <Avatar 
                      alt={`${values.firstName} ${values.lastName}`}
                      src={imageUrl}
                      sx={{ width: 56, height: 56 }}
                    >
                      {avatarFallback()}
                    </Avatar>
                    <div className="content-center">
                      {!imageUrl && (
                         <Button onClick={() => setIsCropImageOpen(true)} label={'Add photo'} />
                        
                      )}
                      {imageUrl && (
                       <Button theme={'red'} onClick={() => setConfirmModalOpen(true)} label={'Delete photo'} />
                      )}
                    </div>
                    <div className="content-center"><span className="text-sm text-sky-400">Only JPG, JPEG images allowed</span></div>
                  </div>
                </Label>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
                  <div className='w-full'>
                    <Label label="First name *">                      
                      <Input 
                        name="firstName" 
                        placeholder="First name"
                        value={values.firstName}
                        onChange={(e) => setFieldValue('firstName', e.target?.value)}
                      />
                    </Label>
                    <ErrorMessage 
                      name="firstName"
                      render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                    />
                  </div>
                  <div className='w-full'>
                    <Label label={'Last name *'}>                      
                      <Input 
                        name="lastName"
                        value={values.lastName}
                        placeholder="Last name"
                        onChange={(e) => setFieldValue('lastName', e.target?.value)} 
                      />                                     
                      <ErrorMessage 
                        name="lastName"
                        render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                      />
                    </Label>
                  </div>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <Label label={'Date of birth *'}>                    
                    <CustomDatePicker
                      name='dateOfBirth'
                      value={values.dateOfBirth}
                      onChange={(date) => handleCalendarChange('dateOfBirth', date)}
                      invalid={hasValidationIssue('dateOfBirth')}
                    />                                
                    <ErrorMessage 
                      name="dateOfBirth"
                      render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                    />
                  </Label>
                  <Label label={'Social security number *'}>                   
                    <PatternFormat
                      name="socialSecurityNumber"                        
                      format='###-##-####'                        
                      placeholder='999-99-9999'
                      value={values['socialSecurityNumber']}
                      onChange={(e) => {
                        const numValue = e.target.value.replaceAll('-', '').replaceAll('_', '');
                        setFieldValue('socialSecurityNumber', numValue).then(() => {
                          setTouched({ socialSecurityNumber: true }, true);
                        });
                      }}
                      invalid={hasValidationIssue('socialSecurityNumber')}
                      customInput={Input}
                      valueIsNumericString
                      mask='_'
                    />
                    <ErrorMessage 
                      name="socialSecurityNumber"
                      render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                    />
                  </Label>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <Label label={'Email address *'}>
                      <Input
                        //iconComponent={<EditTwoTone onClick={() => setIsVerifyEmailModalOpen(true)} />}
                        {...getCommonInputProps('email', 'Email address')}
                        stylesConfig={{ inputStyle: { width: '90%', display: 'inline', marginRight: '7px' } }}
                        disabled={true}
                      />
                      <ErrorMessage 
                        name="email"
                        render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                      />
                      <EmailVerificationModal
                        userInfo={userProfile}
                        setIsVerifyEmailModalOpen={setIsVerifyEmailModalOpen}
                        isVerifyEmailModalOpen={isVerifyEmailModalOpen}
                        onCloseCallback={(newEmail) => setFieldValue('email', newEmail)}
                      />
                      {hasValidationIssue('email') && <p className='text-xs text-error py-1'>{errors.email}</p>}
                      {!(userProfileVerification && isEmailValid) && (
                        <div className='flex flex-wrap items-center gap-3 mt-4'>
                          <p className='text-error'>Email verification missing</p>
                          <Button
                            theme='red-outline'
                            label={'Verify'}
                            onClick={async () => await verifyEmail()}
                            disabled={isVerificationOfEmail}
                          ></Button>
                        </div>
                      )}
                    </Label>                    
                  </div>
                  <div className='w-full'>
                    <Label label={'Phone number *'}>
                      <ErrorValidationMessage touched={touched.phoneNumber} message={errors.phoneNumber}>
                        <PhoneInput
                          isValid={!Boolean(errors.phoneNumber && touched.phoneNumber)}
                          value={values.phoneNumber}
                          onChange={(value) => {
                            setFieldValue('phoneNumber', value);
                          }}
                          // TODO: CHANGE WHEN WE WILL ADD FLOW TO UPDATE NUMBER FROM PROFILE
                          disabled
                          inputStyle={phoneNumberDisabledInputStyles}
                          disableDropdown
                          buttonStyle={phoneNumberDisabledButtonStyles}
                        />
                      </ErrorValidationMessage>
                    </Label>
                  </div>
                </div>
                {/* {renderTabs()} */}
              </div>
            </div>
            
          {/***
            **********************************************************************************
            * CURRENT RESIDENTIAL ADDRESS 
            **********************************************************************************
          */}

            <div className='address-form'>              
              <FormDescription {...personalAddressFormDescription} />       
              <div className='grid grid-cols-1 md:grid-cols-3 gap-3 pt-4'>
                <div className="col-span-2">
                  <Label label={'Home address'}>
                      <RenterPersonalDetailsFormAddressField
                        value={values.addressField}
                        setValue={(value) => {
                          setFieldValue('addressField', value.address);
                          if (!isNotEmptyString(value.address)) {
                            setFieldValue('address', '');
                            setFieldValue('city', '');
                            setFieldValue('state', '');
                            setFieldValue('zipCode', '');
                          }
                        }}
                        onSelectAddress={(value) => {
                          setFieldValue('address', `${value.streetName} ${value.streetNumber}`);
                          setFieldValue('city', `${value.city}`);
                          setFieldValue('state', `${value.state}`);
                          setFieldValue('zipCode', `${value.zip}`);
                          setFieldValue(
                            'addressField',
                            formatAddress({
                              address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                              city: value.city,
                              state: value.state,
                              zip: value.zip,
                            }),
                          );
                        }}
                        fieldName={'addressField'}
                        invalid={Boolean(errors.addressField && touched.addressField)}
                      />
                      {Boolean(errors.addressField && touched.addressField) && (
                        <div className='text-xs text-error mt-2'>{errors.addressField}</div>
                      )}
                  </Label>
                </div>
                <div>
                  <Label label="Time at residence" />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div>
                      <Input
                        {...getCommonInputProps('durationYears', 'Years')}
                        type="number"
                        min={0}
                        onChange={(e) => setFieldValue('durationYears', e.target?.value)} 
                      />
                      {hasValidationIssue('durationYears') && (
                        <p className='text-xs text-error py-1'>{errors.durationYears}</p>
                      )}
                    </div>                      
                    <div>
                      <Input
                        {...getCommonInputProps('durationMonths', 'Months')}
                        type="number"
                        min={0}
                        onChange={(e) => setFieldValue('durationMonths', e.target?.value)} 
                      />
                      {hasValidationIssue('durationMonths') && (
                        <p className='text-xs text-error py-1'>{errors.durationMonths}</p>
                      )}
                    </div> 
                  </div>   
                </div>             
              </div>
            </div>
          
          {/** END OF CURRENT RESIDENTIAL ADDRESS */}

          <div><Divider /></div>

          {/***
           **********************************************************************************
           * PREVIOUS RESIDENTIAL ADDRESS 
           * ********************************************************************************
           */}

          <div className="previous-residents">
            <div className='flex flex-col gap-1'>
              <div className="flex relative">
                <h2 className='title text-xl font-medium'>{previousResidentsDescription.title}
                  <span className="text-xs font-bold pl-2 text-trueGray" style={{opacity: 0.6}}>optional</span>
                </h2>
                <Tooltip title="Add new">
                  <IconButton 
                    className="absolute top-0 right-0"
                    onClick={handleAddResidenceHistory}
                    disabled={isCollectionButtonDisabled('residences')}
                    sx={{position: 'absolute'}}
                  >
                    <AddOutlined fontSize="small" sx={{color: 'rgb(100,100,100)'}} />
                  </IconButton>
                </Tooltip>  
              </div>
              {
                previousResidentsDescription.subTitle && 
                  <h4 className='description text-trueGray'>
                    {previousResidentsDescription.subTitle}
                  </h4>
              }
            </div>
            <div className='flex flex-col gap-3 pt-4'>
              {
                values.residences && 
                values.residences.length === 0
                && (<Placeholder className="h-[100px]" />)
              }
              {values.residences && values.residences.map((residence, index) => {
                return (
                  <div key={index} 
                    className="relative rounded-md p-4 border border-zinc-200"
                    style={{backgroundColor: 'rgba(252,252,252,.6)'}}
                  > 
                    <div className="grid grid-cols-none md:grid-cols-6 gap-3 gap-y-6">                
                      <div className="col-span-none md:col-span-6">
                        <Tooltip title="Delete">
                          <IconButton 
                            className="top-0 right-0"
                            onClick={(e) => handleRemoveResidenceElement(residence, index)}
                            sx={{ position: 'absolute' }}
                          >
                            <DeleteOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </div> 
                      <div className="col-span-none md:col-span-4">
                        <Label label="Residence address">                
                          <RenterPersonalDetailsFormAddressField
                            value={values.residences[index].addressLine}
                            setValue={(value) => {
                              setFieldValue(`residences.${index}.addressLine`, value.address);
                            }}
                            onSelectAddress={(value) => {
                              setFieldValue(`residences.${index}.address`, `${value.streetName} ${value.streetNumber}`);
                              setFieldValue(`residences.${index}.city`, value.city);
                              setFieldValue(`residences.${index}.state`, value.state);
                              setFieldValue(`residences.${index}.zipCode`, value.zip);                          
                              setFieldValue(
                                `residences.${index}.addressLine`,
                                formatAddress({
                                  address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                  city: value.city,
                                  state: value.state,
                                  zip: value.zip,
                                }));
                            }}
                            fieldName={`residences.${index}.addressLine`}
                            invalid={Boolean(errors[`residences.${index}.addressLine`] && touched[`residences.${index}.addressLine`])}
                          />
                          {Boolean(errors[`residences.${index}.addressLine`] && touched[`residences.${index}.addressLine`]) && (
                            <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.addressField}</div>
                          )}
                        </Label>
                      </div>
                      <div className="col-span-none md:col-span-2">
                        <Label label="Time at residence" />
                        <div className="grid grid-cols-none md:grid-cols-2 gap-x-2">
                          <div>                           
                            <Input 
                              name={`residences.${index}.durationYears`}
                              value={values.residences[index].durationYears} 
                              type="number"
                              min={0}
                              placeholder="Years"
                              onChange={(e) => setFieldValue(`residences.${index}.durationYears`, normilizePeriod(e.target?.value))}      
                            />                       
                            <ErrorMessage 
                                name={`residences.${index}.durationYears`}
                                render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </div>
                          <div>                           
                            <Input 
                              name={`residences.${index}.durationMonths`}
                              value={values.residences[index].durationMonths}
                              type="number"
                              min={0}
                              placeholder="Months" 
                              onChange={(e) => setFieldValue(`residences.${index}.durationMonths`, normilizePeriod(e.target?.value))}
                            />                                             
                            <ErrorMessage 
                              name={`residences.${index}].durationMonths`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </div>              
                        </div>
                      </div>
                      <div className="col-span-none md:col-span-2">
                        <Label label="Landlord name">
                          <Input 
                            name={`residences.${index}.landlordName`}
                            value={values.residences[index].landlordName}
                            placeholder="Landlord name"
                            onChange={(e) => setFieldValue(`residences.${index}.landlordName`, e.target?.value)}
                            maxLength={100}
                          />
                        </Label>
                        <ErrorMessage 
                          name={`residences.${index}.landlordName`}
                          render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        />
                      </div>                    
                      <div className="col-span-none md:col-span-2">
                        <Label label="Email address" >
                        
                            <Input 
                              name={`residences.${index}.landlordEmail`}
                              value={values.residences[index].landlordEmail}
                              placeholder="Email address"
                              onChange={(e) => setFieldValue(`residences.${index}.landlordEmail`, e.target?.value)}
                              maxLength={255}           
                            />
                        </Label>                
                        <ErrorMessage name={`residences.${index}.landlordEmail`}  
                          render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        />  
                      </div>              
                      <div className="col-span-none md:col-span-2">
                        <Label label={'Phone number'}>
                          <PhoneInput
                            //name={`residences.${index}.phoneNumber`}
                            value={values.residences[index].phoneNumber}
                            onChange={(value) => setFieldValue(`residences.${index}.phoneNumber`, value)}
                            inputStyle={{boxShadow: 'unset', borderColor: colors.lightGrey}}
                          />
                        </Label>                                           
                        <ErrorMessage name={`residences.${index}.phoneNumber`}  
                          render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        /> 
                      </div>  
                    </div>
                  </div>
                )
              })}            
            </div>          
          </div>

          {/* END OF PREVIOUS RESIDENTIAL ADDRESS */}

          <div><Divider /></div>

          {/**
           ****************************************************************
           * EMPLOYMENT SECTION
           ****************************************************************
           */}

          <div id="employments">
            <div className='flex flex-col gap-1'>
              <div className="flex relative">
                <h2 className='title text-xl font-medium'>{personalEmploymentDescription.title}
                  <span className="text-xs font-bold pl-2 text-trueGray" style={{opacity: 0.6}}>optional</span>
                </h2>
                <Tooltip title="Add new">
                  <IconButton 
                    className="absolute top-0 right-0"
                    onClick={handleAddEmploymentElement}
                    disabled={isCollectionButtonDisabled('employments')}
                    sx={{position: 'absolute'}}
                  >
                    <AddOutlined fontSize="small" sx={{color: 'rgb(100,100,100)'}} />
                  </IconButton>
                </Tooltip>  
              </div>
              {
                personalEmploymentDescription.subTitle && 
                  <h4 className='description text-trueGray'>
                    {personalEmploymentDescription.subTitle}
                  </h4>
              }
            </div>
            <div className="flex flex-col gap-[18px] pt-4">
              {
                values.employments && 
                values.employments.length === 0
                && (<Placeholder className="h-[100px]" />)
              }  
              {values.employments && values.employments.map((employment, index) => {
                return (
                  <div key={index} 
                    className="relative rounded-md p-5 border border-zinc-200"
                    style={{backgroundColor: 'rgba(252,252,252,.6)'}}
                  >
                    <div className="grid grid-cols-none md:grid-cols-2 gap-3 gap-y-6">
                      <div className="col-span-none md:col-span-2 ">
                        <Tooltip title="Delete">
                          <IconButton 
                          className={"top-0 right-0"}
                          onClick={(e) => handleRemoveEmploymentElement(employment, index)}
                          sx={{ position: 'absolute' }}
                          >
                          <DeleteOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div>
                        <Label label="Job title">                        
                          <Input                                
                            name={`employments.${index}.jobTitle`}                      
                            value={values.employments[index].jobTitle}   
                            placeholder="e.g. Manager"
                            disabled={values.employments[index].retired}
                            onChange={(e) => setFieldValue(`employments.${index}.jobTitle`, e.target?.value)}                       
                          />
                          <ErrorMessage 
                            name={`employments.${index}.jobTitle`}
                            render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                          /> 
                        </Label>
                      </div>
                      <div>
                        <Label label="Employment type">                        
                          <Select
                            name={`employments.${index}.employmentType`}
                            value={values.employments[index].employmentType}
                            options={employmentTypeOptions}    
                            disabled={values.employments[index].retired}                            
                            placeholder="Employment type"
                            onChange={(value) => setFieldValue(`employments.${index}.employmentType`, value)}
                          />                         
                        </Label>
                        <ErrorMessage 
                          name={`employments.${index}.employmentType`}
                          render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        /> 
                      </div>
                      <div>
                        <Label label="Company name">                          
                          <Input 
                            name={`employments.${index}.companyName`}
                            value={values.employments[index].companyName}
                            placeholder="Company name"
                            disabled={values.employments[index].retired}
                            onChange={(e) => setFieldValue(`employments.${index}.companyName`, e.target?.value)}
                          />                            
                          <ErrorMessage 
                            name={`employments.${index}.companyName`}
                            render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                          />     
                        </Label>
                      </div>
                      <div>
                        <Label label="Length of employment">
                          <div className="grid grid-cols-none md:grid-cols-2 gap-x-3">
                            <div>                             
                              <Input
                                name={`employments.${index}.employmentYears`}
                                value={values.employments[index].durationYears}  
                                type="number"
                                min={0}
                                max={100}
                                placeholder="Years"                                    
                                disabled={values.employments[index].retired}
                                onChange={(e) => setFieldValue(`employments.${index}.durationYears`, normilizePeriod(e.target?.value))}
                              />                              
                              <ErrorMessage 
                                name={`employments.${index}.durationYears`}
                                render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                              /> 
                            </div>
                            <div>                              
                              <Input                            
                                name={`employments.${index}.durationMonths`}
                                value={values.employments[index].durationMonths}
                                type="number"
                                min={0}
                                max={1200}
                                placeholder="Months"    
                                disabled={values.employments[index].retired}                             
                                onChange={(e) => setFieldValue(`employments.${index}.durationMonths`, e.target?.value)}
                              />                                                  
                              <ErrorMessage 
                                name={`employments.${index}.durationMonths`}
                                render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                              /> 
                            </div>
                        </div>  
                        </Label>
                      </div>
                      <div>
                        <Label label="Company Address">
                          <RenterPersonalDetailsFormAddressField
                            value={values.employments[index].addressLine}
                            disabled={values.employments[index].retired}
                            setValue={(value) => {
                              setFieldValue(`employments.${index}.addressLine`, value.address);
                            }}
                            onSelectAddress={(value) => {
                              setFieldValue(`employments.${index}.address`, `${value.streetName} ${value.streetNumber}`);
                              setFieldValue(`employments.${index}.city`, value.city);
                              setFieldValue(`employments.${index}.state`, value.state);
                              setFieldValue(`employments.${index}.zipCode`, value.zip);
                              setFieldValue(
                                `employments.${index}.addressLine`,
                                formatAddress({
                                  address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                  city: value.city,
                                  state: value.state,
                                  zip: value.zip,
                                }),
                              );
                            }}
                            fieldName={`employments.${index}.addressLine`}
                            invalid={hasValidationIssue(`employments.${index}.addressLine`)}
                          />
                        </Label>
                      </div>
                      <div>
                        <Label label={'Household income'}>
                          <Input 
                            name={`employments.${index}.householdIncome`}
                            value={values.employments[index].householdIncome}
                            type="number"
                            placeholder="$ 0.0"
                            min={0}
                            disabled={values.employments[index].retired}
                            onChange={(e) => setFieldValue(`employments.${index}.householdIncome`, e.target?.value)}
                          />                         
                          <ErrorMessage 
                              name={`employments.${index}.householdIncome`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                          />                          
                        </Label>
                      </div>
                      <div>
                        <Checkbox
                          name={`employments.${index}.retired`}
                          label="Retired"
                          checked={values.employments[index].retired}
                          disabled={values.employments[index].selfEmployed}
                          onChange={(e) => setFieldValue(`employments.${index}.retired`, e.target?.checked)}
                        />
                      </div>
                      <div>
                        <Checkbox
                          name={`employments.${index}.selfEmployed`}
                          label="Self-Employed"
                          checked={values.employments[index].selfEmployed}
                          disabled={values.employments[index].retired}
                          onChange={(e) => setFieldValue(`employments.${index}.selfEmployed`, e.target?.checked)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })} 
            </div>
          </div>

          {/***
           *************************************************************
           * BEGIN CREDIT OBLIGATIONS 
           *************************************************************
           */}
          
          <div id="credit-obligations">
            <div className='flex flex-col gap-1'>
              <div className="flex relative">
                <h2 className='title text-xl font-medium'>{creditObligationDescription.title}
                  <span className="text-xs font-bold pl-2 text-trueGray" style={{opacity: 0.6}}>optional</span>
                </h2>
                <Tooltip title="Add new">
                  <IconButton 
                    className="absolute top-0 right-0"
                    onClick={handleAddCreditObligationElement}
                    sx={{position: 'absolute'}}
                  >
                    <AddOutlined fontSize="small" sx={{color: 'rgb(100,100,100)'}} />
                  </IconButton>
                </Tooltip>  
              </div>
              {
                creditObligationDescription.subTitle && 
                  <h4 className='description text-trueGray'>
                    {creditObligationDescription.subTitle}
                  </h4>
              }
            </div>
            <div className="flex flex-col gap-[18px] pt-4">
              {
                values.creditObligations && 
                values.creditObligations.length === 0
                && (<Placeholder className="h-[100px]" />)
              }  
              {values.creditObligations && values.creditObligations.map((creditObligation, index) => {
                return (
                  <div key={index} 
                    className="relative rounded-md p-5 border border-zinc-200"
                    style={{backgroundColor: 'rgba(252,252,252,.6)'}}
                  >                  
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 gap-y-6"> 
                      <div className="col-span-none md:col-span-2 ">
                        <Tooltip title="Delete">
                          <IconButton 
                            className={"top-0 right-0"}
                            onClick={(e) => handleRemoveCreditObligationElement(creditObligation, index)}
                            sx={{ position: 'absolute' }}
                          >
                            <DeleteOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </div> 
                      <div>
                        <Label label="Type">                        
                          <Select
                            name={`creditObligations.${index}.obligationType`}
                            value={values.creditObligations[index].obligationType}
                            options={creditObligationTypeOptions}                              
                            onChange={(value) => setFieldValue(`creditObligations.${index}.obligationType`, value)}
                            invalid={hasValidationIssue(`creditObligations.${index}.obligationType`)}
                            placeholder='Please, select type'
                          />                   
                        </Label>
                        <ErrorMessage 
                          name={`creditObligations.${index}.obligationType`}
                          render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        /> 
                      </div>
                      <div>
                        <Label label="Amount">                          
                          <Input
                            name={`creditObligations.${index}.amount`}
                            value={values.creditObligations[index].amount}
                            type="number"
                            min={0}
                            onChange={(e) => setFieldValue(`creditObligations.${index}.amount`, e.target?.value) }
                          />                        
                        </Label>
                        <ErrorMessage 
                            name={`creditObligations.${index}.amount`}
                            render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                        />
                      </div>
                    </div>    
                  </div>                 
                );
              })}
            </div>
          </div>

        {/* END CREDIT OBLIGATION */}

        <div><Divider /></div>

        {/**
         **********************************************************************
         * EDUCATION SECTION
         **********************************************************************
         */}

          <div className='education-form'>
            <FormDescription {...personalEducationDescription} isOptional={true} />
            <div className='flex flex-col gap-[18px] pt-4'>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='w-full'>
                  <Label label={'Institution name'}>
                    <Input {...getCommonInputProps('institutionName', 'Institution name')} />
                  </Label>
                </div>
                <div className='w-full'>
                  <Label label={'Highest degree earned'}>
                    <Select {...getSelectProps('educationEarned', educationEarnedOptions, 'Highest degree earned')} />
                  </Label>
                </div>
              </div>
              <Label label={'Education address'}>
                <div className='place-autocomplete'>
                  <RenterPersonalDetailsFormAddressField
                    value={values.educationAddressField}
                    setValue={(value) => {
                      setFieldValue('educationAddressField', value.address);
                      if (!isNotEmptyString(value.address)) {
                        setFieldValue('educationAddress', '');
                        setFieldValue('educationCity', '');
                        setFieldValue('educationState', '');
                        setFieldValue('educationZip', '');
                      }
                    }}
                    onSelectAddress={(value) => {
                      setFieldValue('educationAddress', `${value.streetName} ${value.streetNumber}`);
                      setFieldValue('educationCity', `${value.city}`);
                      setFieldValue('educationState', `${value.state}`);
                      setFieldValue('educationZip', `${value.zip}`);
                      setFieldValue(
                        'educationAddressField',
                        formatAddress({
                          address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                          city: value.city,
                          state: value.state,
                          zip: value.zip,
                        }),
                      );
                    }}
                    fieldName={'educationAddressField'}
                    invalid={Boolean(errors.educationAddressField && touched.educationAddressField)}
                  />
                  {Boolean(errors.educationAddressField && touched.educationAddressField) && (
                    <div className='text-red-500 text-sm text-red font-medium mt-2'>
                      {errors.educationAddressField}
                    </div>
                  )}
                </div>
              </Label>
            </div>
          </div>

          {/* END OF EDUCATION SECTION  */}
          
          <div><Divider /></div>

          {/* BEGIN ADDITIONAL OCCUPANT DETAILS */}

          <div id="partners-profile">
            <div className='flex flex-col gap-1 mb-3'>
              <div className="flex relative">
                <h2 className='title text-xl font-medium'>{spouseDetailDescription.title}</h2>
                <Tooltip title="Add new">
                  <IconButton 
                    className="absolute top-0 right-0"
                    onClick={handleAddPartner}
                    sx={{position: 'absolute'}}
                  >
                    <AddOutlined fontSize="small" sx={{color: 'rgb(100,100,100)'}} />
                  </IconButton>
                </Tooltip>  
              </div>
              {
                spouseDetailDescription.subTitle && 
                  <h4 className='description text-trueGray'>
                    {spouseDetailDescription.subTitle}
                  </h4>
              }
            </div>
            {
              values.rentalPartners &&
              values.rentalPartners.length === 0
              && (<Placeholder className="h-[100px]" />)
            }
            <div className='flex flex-col gap-5'>
              {values.rentalPartners.map((rentalPartner, index) => {
                const partnerFormName = `rentalPartners.${index}`;
                const partnerFormValue = values.rentalPartners[index];
                const isAdult = isPersonAdult(rentalPartner.dateOfBirth);
                return (
                  <div key={partnerFormName}>
                    <div className="flex flex-col gap-4 rounded-md p-5 border border-zinc-200">
                      <div className="flex relative">
                        <h2 className='title text-xl font-medium'>{spouseDetailDescription.title}
                          {getAgeInfo(rentalPartner.dateOfBirth)}
                        </h2>
                        <Tooltip title="Delete">
                          <IconButton
                            className={"top-0 right-0"}
                            onClick={(e) => handleRemovePartner(index)}
                            sx={{ position: 'absolute' }}
                          >
                            <DeleteOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>  
                      </div>

                      <div className='grid grid-cols-1 md:grid-cols-2 gap-3 gap-y-6 pt-4'>
                        <div className="col-span-none md:col-span-2 ">
                          <Tooltip title="Delete">
                            <IconButton
                              className={"top-0 right-0"}
                              onClick={(e) => handleRemovePartner(index)}
                              sx={{ position: 'absolute' }}
                            >
                              <DeleteOutlinedIcon fontSize="small"/>
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div>
                          <Label label="First name *">
                            <Input
                              name={`${partnerFormName}.firstName`}
                              value={partnerFormValue.firstName}
                              placeholder="First name"
                              onChange={(e) => setFieldValue(`${partnerFormName}.firstName`, e.target?.value)}
                            />
                            <ErrorMessage
                              name={`${partnerFormName}.firstName`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        <div>
                          <Label label="Last name *">
                            <Input
                              name={`${partnerFormName}.lastName`}
                              value={partnerFormValue.lastName}
                              placeholder="Last name"
                              onChange={(e) => setFieldValue(`${partnerFormName}.lastName`, e.target?.value)}
                            />
                            <ErrorMessage
                              name={`${partnerFormName}.lastName`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        <div>
                          <Label label="Date of birth *">
                            <CustomDatePicker
                              name={`${partnerFormName}.dateOfBirth`}
                              value={partnerFormValue.dateOfBirth}
                              onChange={(date) => handleCalendarChange(`${partnerFormName}.dateOfBirth`, date)}
                              invalid={hasValidationIssue(`${partnerFormName}.dateOfBirth`)}
                            />
                          <ErrorMessage
                            name={`${partnerFormName}.dateOfBirth`}
                            render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                          />
                        </Label>
                        </div>
                        <div>
                          <Label label="Social security number *">
                            <PatternFormat
                              name={`${partnerFormName}.socialSecurityNumber`}
                              value={partnerFormValue.socialSecurityNumber}
                              format='###-##-####'
                              placeholder='999-99-9999'
                              onChange={(e) => {
                                const numValue = e.target.value.replaceAll('-', '').replaceAll('_', '');
                                setFieldValue(`${partnerFormName}.socialSecurityNumber`, numValue);
                              }}
                              invalid={hasValidationIssue(`${partnerFormName}.socialSecurityNumber`)}
                              customInput={Input}
                              valueIsNumericString
                              mask='_'
                            />
                            <ErrorMessage
                              name={`${partnerFormName}.socialSecurityNumber`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        <div className="col-span-none md:col-span-2">
                          <Label label={'Present address *'}>
                            <RenterPersonalDetailsFormAddressField
                              value={partnerFormValue.addressLine}
                              setValue={(value) => {
                                setFieldValue(`${partnerFormName}.addressLine`, value.address);
                              }}
                              onSelectAddress={(value) => {
                                setFieldValue(`${partnerFormName}.address`, `${value.streetName} ${value.streetNumber}`);
                                setFieldValue(`${partnerFormName}.city`, `${value.city}`);
                                setFieldValue(`${partnerFormName}.state`, `${value.state}`);
                                setFieldValue(`${partnerFormName}.zipCode`, `${value.zip}`);
                                setFieldValue(`${partnerFormName}.addressLine`,
                                  formatAddress({
                                    address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                    city: value.city,
                                    state: value.state,
                                    zip: value.zip,
                                  })
                                );
                              }}
                              fieldName={`${partnerFormName}.addressLine`}
                              invalid={Boolean(errors[`${partnerFormName}.addressLine`] && touched[`${partnerFormName}.addressLine`])}
                            />
                            <ErrorMessage
                              name={`${partnerFormName}.addressLine`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        <div>
                          <Label label="Phone number">
                            <PhoneInput
                              value={partnerFormValue.phoneNumber1}
                              onChange={(value) => setFieldValue(`${partnerFormName}.phoneNumber1`, value)}
                              inputStyle={{boxShadow: 'unset', borderColor: colors.lightGrey }}
                            />
                            <ErrorMessage
                                name={`${partnerFormName}.phoneNumber1`}
                                render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        <div>
                          <Label label="Driver license">
                            <Input
                              name={`${partnerFormName}.driverLicense`}
                              value={partnerFormValue.driverLicense}
                              disabled={!isPersonCanHaveDriverLicense(rentalPartner.dateOfBirth)}
                              placeholder="Driver license"
                              onChange={(e) => setFieldValue(`${partnerFormName}.driverLicense`, e.target?.value)}
                            />
                            <ErrorMessage
                              name={`${partnerFormName}.driverLicense`}
                              render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                            />
                          </Label>
                        </div>
                        {isAdult && <div className="col-span-none md:col-span-1">
                          <Label label="Email address *" >
                              <Input 
                                name={`${partnerFormName}.email`}
                                value={partnerFormValue.email}
                                placeholder="Email address"
                                onChange={(e) => setFieldValue(`${partnerFormName}.email`, e.target?.value)}
                                maxLength={255} 
                              />
                          </Label>                
                          <ErrorMessage name={`${partnerFormName}.email`}  
                            render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                          />
                        </div>}
                      </div>
                      {isAdult && <div className='flex flex-col gap-1 pt-6'>
                        <div className="flex relative">
                          <h2 className='title text-xl font-medium'>{spouseEmploymentDescription.title}</h2>
                          <Tooltip title="Add new">
                            <IconButton
                              className="absolute top-0 right-0"
                              onClick={() => handleAddPartnerEmployment(index)}
                              disabled={isAddPartnerEmploymentButtonDisabled(rentalPartner)}
                              sx={{position: 'absolute'}}
                            >
                              <AddOutlined fontSize="small" sx={{color: 'rgb(100,100,100)'}} />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {
                          spouseEmploymentDescription.subTitle &&
                          <h4 className='description text-trueGray'>
                            {spouseEmploymentDescription.subTitle}
                          </h4>
                        }
                      </div>}
                      {
                        rentalPartner.rentalPartnerEmployments &&
                        isAdult &&
                        rentalPartner.rentalPartnerEmployments.length === 0
                        && (<Placeholder className="h-[100px]" />)
                      }
                      {rentalPartner.rentalPartnerEmployments && isAdult && rentalPartner.rentalPartnerEmployments.map((_, partnerEmploymentIndex) => {
                        const partnerEmploymentFormValue = values.rentalPartners[index].rentalPartnerEmployments[partnerEmploymentIndex];
                        const partnerEmploymentFormName = `${partnerFormName}.rentalPartnerEmployments.${partnerEmploymentIndex}`;
                        return (
                          <div key={partnerEmploymentFormName}
                            className="relative rounded-md p-5 border border-zinc-200"
                            style={{backgroundColor: 'rgba(252,252,252,.6)'}}
                          >
                            <div className="grid grid-cols-none md:grid-cols-2 gap-3 gap-y-6" >
                              <div className="col-span-none md:col-span-2 ">
                                  <Tooltip title="Delete">
                                    <IconButton
                                      className={"top-0 right-0"}
                                      onClick={(e) => handleRemovePartnerEmployment(index, partnerEmploymentIndex)}
                                      sx={{ position: 'absolute' }}
                                    >
                                      <DeleteOutlinedIcon fontSize="small"/>
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div>
                                  <Label label="Employer name">
                                    <Input
                                      name={`${partnerEmploymentFormName}.employerName`}
                                      value={partnerEmploymentFormValue.employerName}
                                      maxLength={50}
                                      placeholder="Employer name"
                                      onChange={(e) => setFieldValue(`${partnerEmploymentFormName}.employerName`, e.target?.value)}
                                    />
                                  </Label>
                                  <ErrorMessage
                                    name={`${partnerEmploymentFormName}.employerName`}
                                    render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                                  />
                                </div>
                                <div>
                                  <Label label={'Date of employment'}>
                                    <CustomDatePicker
                                      name={`${partnerEmploymentFormName}.employedAt`}
                                      value={partnerEmploymentFormValue.employedAt}
                                      onChange={(date) => handleCalendarChange(`${partnerEmploymentFormName}.employedAt`, date)}
                                    />
                                  </Label>
                                  <ErrorMessage
                                    name={`${partnerEmploymentFormName}.employedAt`}
                                    render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                                  />
                                </div>
                                <div>
                                  <Label label="Employer address">
                                    <RenterPersonalDetailsFormAddressField
                                      value={partnerEmploymentFormValue.addressLine}
                                      setValue={(value) => {
                                        setFieldValue(`${partnerEmploymentFormName}.addressLine`, value.address);
                                      }}
                                      onSelectAddress={(value) => {
                                        setFieldValue(`${partnerEmploymentFormName}.address`, `${value.streetName} ${value.streetNumber}`);
                                        setFieldValue(`${partnerEmploymentFormName}.city`, value.city);
                                        setFieldValue(`${partnerEmploymentFormName}.state`, value.state);
                                        setFieldValue(`${partnerEmploymentFormName}.zipCode`, value.zip);
                                        setFieldValue(
                                          `${partnerEmploymentFormName}.addressLine`,
                                          formatAddress({
                                            address: `${value.streetName}${value.streetNumber ? ' ' + value.streetNumber : ''}`,
                                            city: value.city,
                                            state: value.state,
                                            zip: value.zip,
                                          }),
                                        );
                                      }}
                                      fieldName={`${partnerEmploymentFormName}.addressLine`}
                                      invalid={hasValidationIssue(`${partnerEmploymentFormName}.addressLine`)}
                                    />
                                    {Boolean(errors[`${partnerEmploymentFormName}.addressLine`] && touched[`${partnerEmploymentFormName}.addressLine`]) && (
                                      <div className='text-red-500 text-sm text-red font-medium mt-2'>{errors.addressField}</div>
                                    )}
                                  </Label>
                                </div>
                                <div>
                                  <Label label="Monthly income">
                                    <Input
                                        name={`${partnerEmploymentFormName}.monthlyIncome`}
                                        type="number"
                                        min={0}
                                        placeholder="$ 0.0"
                                        value={partnerEmploymentFormValue.monthlyIncome}
                                        onChange={(e) => setFieldValue(`${partnerEmploymentFormName}.monthlyIncome`, e.target.value)}
                                    />
                                    <ErrorMessage
                                      name={`${partnerEmploymentFormName}.monthlyIncome`}
                                      render={(errorMessage) => (<span className='text-xs text-error py-1'>{errorMessage}</span>)}
                                    />
                                  </Label>
                                </div>
                              </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              )}
            </div>
          </div>

          {/* END OF ADDITIONAL OCCUPANT DETAILS */}          

          <div><Divider /></div>

          {/* BEGIN OF QUESTIONS / ANSWERS SECTION */}

          <div id="convictions">
            <div className="grid grid-cols-none md:grid-cols-6 gap-2 mt-3 p-3">
              <div className="col-span-4"><h2 className="font-semibold">Have any of the occupants listed above ever been:</h2></div>
              <Label label="Yes" />
              <Label label="No" />
              <div className="col-span-4 pl-2">
                <h4 className="font-normal">Convicted of a felony</h4>
              </div>
              <div>
                <Checkbox 
                  name="felonyConviction-y"
                  disabled={values.isFelonyConviction === false}
                  checked={values.isFelonyConviction === true}
                  onChange={(e) => handleConvictionStatementChanged('isFelonyConviction', e.currentTarget.checked ? true : null)}                     
                />
              </div>
              <div>
                <Checkbox       
                  name="felonyConviction-n"              
                  disabled={values.isFelonyConviction === true}
                  checked={values.isFelonyConviction === false}
                  onChange={(e) => handleConvictionStatementChanged('isFelonyConviction', e.currentTarget.checked ? false : null)}    
                />
              </div>
              <div className="col-span-4 pl-2"><h4 className="font-normal">Received deferred adjudication for a felony</h4></div>
              <div>
                <Checkbox
                    name="felonyDeferredAdjudication-y"
                    disabled={values.isFelonyDeferredAdjudication === false}
                    checked={values.isFelonyDeferredAdjudication === true}
                    onChange={(e) => handleConvictionStatementChanged('isFelonyDeferredAdjudication', e.currentTarget.checked ? true : null)}
                />
              </div>
              <div>
                <Checkbox
                  name="felonyDeferredAdjudication-n"
                  disabled={values.isFelonyDeferredAdjudication === true}
                  checked={values.isFelonyDeferredAdjudication === false}
                  onChange={(e) => handleConvictionStatementChanged('isFelonyDeferredAdjudication', e.currentTarget.checked ? false : null)}
                />
              </div>
              <div className="col-span-4 pl-2">
                <h4 className="font-normal">Been evicted, broken a lease or declared bankruptcy</h4>
              </div>
              <div>
                  <Checkbox  
                    name="bankruptcyDeclaration-y"
                    disabled={values.isBankruptcyDeclaration === false}
                    checked={values.isBankruptcyDeclaration === true}
                    onChange={(e) => handleConvictionStatementChanged('isBankruptcyDeclaration', e.currentTarget.checked ? true : null)}
                  />
              </div>
              <div>
                <Checkbox
                    name="bankruptcyDeclaration-n"  
                    disabled={values.isBankruptcyDeclaration === true}
                    checked={values.isBankruptcyDeclaration === false}
                    onChange={(e) => handleConvictionStatementChanged('isBankruptcyDeclaration', e.currentTarget.checked ? false : null)}
                  />
              </div>                
            </div>            
          </div>
            
          {/* END  OF  QUESTIONS / ANSWERS SECTION */}

          <div><Divider /></div>

          {/* BEGIN OF PETS SECTION */}

          <div className='pets-form'>
            <FormDescription {...petsDescription} isOptional={true} />
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 pt-4'>
              <div className='w-full'>
                <Label label={'Pets'}>
                  <ErrorValidationMessage touched={touched.petsCount} message={errors.petsCount}>
                    <Input
                      name={'petsCount'}
                      value={values.petsCount}
                      onChange={(e) => stringAsIntegerNumber(e, `petsCount`, setFieldValue)}
                      invalid={Boolean(errors.petsCount && touched.petsCount)}
                      placeholder='0'
                    />
                  </ErrorValidationMessage>
                </Label>
              </div>

            <div className='w-full'>
                <Label label={'Type'}>
                  <ErrorValidationMessage touched={touched.petsTypes} message={errors.petsTypes}>
                    <MultiSelect
                      name={'petsTypes'}
                      options={petTypeOptions}
                      value={values.petsTypes}
                      onChange={(value) => {
                        setFieldValue('petsTypes', value)
                      }}
                      invalid={Boolean(errors.petsTypes && touched.petsTypes)}
                      placeholder='dog, cat'
                    />
                  </ErrorValidationMessage>
                </Label>
              </div>

              <div className='w-full'>
                <Label label={'Weight (largest pet)'}>
                  <ErrorValidationMessage touched={touched.petsWeight} message={errors.petsWeight}>
                    <Input
                      name={'petsWeight'}
                      value={values.petsWeight}
                      onChange={(e) => stringAsFloatNumber(e, `petsWeight`, setFieldValue)}
                      invalid={Boolean(errors.petsWeight && touched.petsWeight)}
                      placeholder='22lbs'
                    />
                  </ErrorValidationMessage>
                </Label>
              </div>
            </div>
          </div>

          {/* END OF PETS SECTION */}
            
          <div className="consent-acknowledgement">
              <p className="bg-sky-50 rounded-[18px] text-nowrap p-3">
                The above listed applicant declares that all statements made in this application are true and complete. Applicant hereby
                authorizes the Axxelist to verify all of the information in this application and obtain credit reports on the above listed 
                applicant and/or applicants. If applicant(s) provided any false information Axxelist/Landlord is entitled to reject the 
                application and retain all application fees as liquidated damages for Axxelist’s/Landlord’s time and expenses in processing 
                this application. Applicant shall pay nonrefundable pre-application fee to process the application. 
              </p>
          </div>          
          <div>
            <Button label="Accept acknowledgment" theme="sky-text"
              className="text-[13px]"
              disabled={isConsentAcknowledgmentDisabled()}
              onClick={handleOpenConsentAcknowledgment} />
          </div>
          <div className='button-wrapper flex justify-end gap-3 items-center'>
            <Progress
              strokeColor={colors.primary}
              percent={totalDataUoadingPercent}
              className={`m-0 ${isUpoadingProgressVisible ? '' : 'opacity-0'} transition ease-in-out delay-150`}
            />
            <Button
              label={'Save'}
              theme={'primary'}
              onClick={() => {
                // if (!isValid) {
                //   changeInvalidDocumentTab(errors);
                // }
                submitForm();
              }}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </FormikProvider>
        )}
      </div>
    </div>
  );
};

export { RenterPersonalDetailsForm };
