import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BackgroundCheckReport from './BackgroundCheckReport/BackgroundCheckReport';
import CreditCheckReport from './CreditCheckReport/CreditCheckReport';
import { useEffect, useState } from 'react';
import { ApplicantScreeningReportDto } from '../types';
import useApplicantScreeningReport from 'hooks/query/offers/use-get-applicant-screening-report';
import { Loader } from 'legacy-components/componets';
import BlurredContainer from 'components/organisms/BlurredContainer/BlurredContainer';

interface Props {
  renterId: string;
}

const TenantScreeningSection = ({ renterId }: Props) => {
  const backgroundCheckBlurredTitle = 'The background check report is not completed. Please wait till it finished or request it from renter.';
  const creditCheckBlurredTitle = 'The credit check report is not completed. Please wait till it finished or request it from renter.';
  const reportStyles = 'flex-col basis-1/2 justify-between border-[1.5px] border-gray rounded-lg py-3';

  const [screeningReports, setScreeningReports] = useState<ApplicantScreeningReportDto[]>(
    [
      {
        firstName: '',
        lastName: '',
        isTenant: true,
      }
    ]
  );
  
  const { data: applicantScreeningReport, isLoading: isScreeningDataLoading } = useApplicantScreeningReport(renterId);

  useEffect(() => {
    if (!applicantScreeningReport) return;
    setScreeningReports(applicantScreeningReport);
  }, [applicantScreeningReport]);

  if (isScreeningDataLoading) {
    return <Loader />;
  }

  return (
    <div className='p-6 border-[1.5px] border-gray rounded-lg'>
      <h3 className='text-2xl font-bold leading-normal pb-5'>Background/Credit check information</h3>
      {!!screeningReports?.length && screeningReports.map((applicant, index) => (
        <Accordion key={index} defaultExpanded={applicant.isTenant}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h3 className='text-xl font-bold'>{applicant.firstName + ' ' + applicant.lastName}</h3>
          </AccordionSummary>
          <AccordionDetails className='flex gap-4'>
            <BlurredContainer styles={reportStyles} isBlurred={!applicant.backgroundCheckReport} blurredTitle={backgroundCheckBlurredTitle}>
              <BackgroundCheckReport report={applicant.backgroundCheckReport} isBlurred={!applicant.backgroundCheckReport} />
            </BlurredContainer>
            <BlurredContainer styles={reportStyles} isBlurred={!applicant.backgroundCheckReport} blurredTitle={creditCheckBlurredTitle}>
              <CreditCheckReport report={applicant.creditCheckReport} isBlurred={!applicant.backgroundCheckReport }/>
            </BlurredContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default TenantScreeningSection