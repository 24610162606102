import { ISignUpRenterValues } from 'legacy-pages/auth/sign-up-renter-form/sign-up-renter-form';
import {
  PropertyManagerSignUpRequestDto,
  UserSignUpRequestDto,
} from 'common/types/services/api/user/user-sign-up-request-dto.type';
import { AuthRole } from 'common/enums/enums';
import { ISignUpIndependentLandlordValues } from 'legacy-pages/auth/sign-up-independent-landlord-form/sign-up-independent-landlord-form';
import { ISignUpPropertyManagerValues } from 'legacy-pages/auth/sign-up-property-manager-form/sign-up-property-manager-form';
import { getParamFromLocation } from 'common/utils/get-param-from-location';
import { ISignUpPartnerValues } from 'legacy-pages/auth/sign-up-partner-form/sign-up-partner-form';

export const signUpPartnerFormToDtoSignUpRequest = (values: ISignUpPartnerValues): UserSignUpRequestDto => {
  const partner = signUpRenterFormToDtoSignUpRequest(values);

  partner.masterAgreementApplicantId = values.partnerRefId;

  return partner;
};

export const signUpRenterFormToDtoSignUpRequest = (values: ISignUpRenterValues): UserSignUpRequestDto => {
  const referralToken = getParamFromLocation('referralToken');
  return {
    role: AuthRole.Renter,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    phoneNumber: `+${values.phone}`,
    dateOfBirth: values.dateOfBirth,
    captchaToken: values.captchaToken,
    referralToken: referralToken,
  };
};

export const signUpLandlordIndependentFormToDtoSignUpRequest = (
  values: ISignUpIndependentLandlordValues,
): Omit<UserSignUpRequestDto, 'dateOfBirth'> => {
  return {
    role: AuthRole.Landlord, // FIXME: Fix if authentication by roles on the side of the backend will be implemented
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    phoneNumber: `+${values.phone}`,
    captchaToken: values.captchaToken,
  };
};

export const signUpLandlordManagerFormToDtoSignUpRequest = (
  values: ISignUpPropertyManagerValues,
): PropertyManagerSignUpRequestDto => {
  return {
    role: AuthRole.Landlord, // FIXME: Fix if authentication by roles on the side of the backend will be implemented
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    password: values.password,
    phoneNumber: `+${values.phone}`,
    businessEmail: values.businessEmail,
    businessPhone: values.businessPhone,
    companyName: values.companyName,
    companyWebsite: values.companyWebsite,
    captchaToken: values.captchaToken,
  };
};
