import { SelectOption } from 'common/types/types';
import UsStates from 'common/utils/us-states';

export const profileStepperList = [
  {
    id: 'personal',
    title: 'Personal',
  },
  {
    id: 'payment-details',
    title: 'Payment',
  },
  {
    id: 'verification',
    title: 'Verification',
  }
];

export const statesSelectOptions: SelectOption[] = UsStates.map((item) => {
  return {
    label: item.name,
    value: item.abbreviation,
  };
});

export const personalDetailsFormDescription = {
  title: 'Personal Details',
  subTitle: 'Please start filling out your application by filling in your personal information',
};

export const documentFormDescription = {
  title: 'Documents *',
  subTitle: 'Please upload at least 1 of the following documents',
};

export const personalAddressFormDescription = {
  title: 'Current Residential Address *',
  subTitle: 'To avoid service restrictions, ensure you fill out your address details',
};

export const personalEmploymentDescription = {
  title: 'Employment',
  subTitle: 'Please start filling out your application by filling in your personal information',
};

export const personalEducationDescription = {
  title: 'Education',
  subTitle: 'Please start filling out your application by filling in your personal information',
};

export const petsDescription = {
  title: 'Pets',
  subTitle: 'Please start filling out your application by filling in your personal information',
};

export const previousResidentsDescription = {
  title: 'Previous Residential Address',
  subTitle: 'Please provide an information of your past residential address',
};

export const spouseDetailDescription = {
  title: 'Spouse / Additional Occupant',
  subTitle: 'Please provide an information of your partner',
};

export const spouseEmploymentDescription = {
  title: 'Spouse / Additional Occupant Employment',
  subTitle: 'Please provide an information of your spouse employment',
};

export const creditObligationDescription = {
  title: 'Credit Obligations',
  subTitle: 'Please provide an information of credit obligation',
};

export const consentAcknowledgementDescription = {
  title: 'Consent & Acknowledgement'
};

export const employmentTypeOptions: SelectOption[] = [
  { label: 'Full-time', value: 'fullTime' },
  { label: 'Part-time', value: 'partTime' },
];

export const educationEarnedOptions: SelectOption[] = [
  { label: 'High School', value: 'highSchool' },
  { label: "College Associate's", value: 'collegeAssociates' },
  { label: "College Bachelor's", value: 'collegeBachelors' },
  { label: "Master's", value: 'masters' },
  { label: 'PHD/Doctorate', value: 'phdDoctorate' },
];

export const petTypeOptions: SelectOption[] = [{
  value: 'Cat',
  label: 'Cat'
}, {
  value: 'Dog',
  label: 'Dog'
}];

export const creditObligationTypeOptions: SelectOption[] = [{
    value: 'rent',
    label: 'Rent'
  }, {
    value: 'mortgage',
    label: 'Mortgage'
  },
  {
    value: 'creditCard',
    label: 'Credit card'
  },
  {
    value: 'carLoansOrLease',
    label: 'Car loans / lease'
  },
  {
    value: 'personalLoans',
    label: 'Personal loans'
  },
  {
    value: 'studentLoans',
    label: 'Student loans'
  },
  {
    value: 'bankLoans',
    label: 'Bank loans'
  }];
