const ADULT_AGE = 18;
const DRIVER_LICENSE_AGE = 16;

const isPersonAgeGreaterThanOrEqualTo = (age: number, birthDate: string) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  // Get the difference in years
  let currentAge = today.getFullYear() - birthDateObj.getFullYear();

  // Adjust if the birth date hasn't occurred yet this year
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      currentAge--;
  }

  return currentAge >= age; 
}

export const isPersonAdult = (birthDate: string) => {
  return isPersonAgeGreaterThanOrEqualTo(ADULT_AGE, birthDate);
}

export const isPersonCanHaveDriverLicense = (birthDate: string) => {
  return isPersonAgeGreaterThanOrEqualTo(DRIVER_LICENSE_AGE, birthDate);
}