import { BackgroundCheckSearchEnum } from "./background-check-search.enum";
import clsx from 'clsx';

export const backgroundCheckSearchResultSection='py-2 border-b border-b-[1px] border-[#f0f0f0] px-5';

const commonStatusStyle = 'font-medium leading-tight px-[10px] py-2 rounded-3xl text-center max-w-[110px]';
const textStyles = 'text-xs md:text-sm lg:text-[15px]'

export const backgroundCheckSearchStyles: Record<BackgroundCheckSearchEnum, string> = {
  [BackgroundCheckSearchEnum.Clear]: clsx('bg-[#F1FFF5] text-[#A0D911]', commonStatusStyle, textStyles),
  [BackgroundCheckSearchEnum.NA]: clsx('bg-[#F1FFF5] text-[#A0D911]', commonStatusStyle, textStyles)
}