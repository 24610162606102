import { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ADD_LEASE_DOCUMENT_BTN, EMPTY_LIST, LEASE_DOCUMENTS } from './LeaseDocuments.constants';
import { AddLeaseDocument } from './AddNewLeaseDocument/AddNewLeaseDocument';
import { PreviewLeaseDocument } from '../../../common/components/PreviewLeaseDocument/PreviewLeaseDocument';
import { Loader } from 'legacy-components/componets';
import { UploadedLeaseDocument } from './UploadedLeaseDocument/UploadedLeaseDocument';
import { useDeleteLeaseDocument, useGetLeaseDocuments } from 'hooks/query';
import { maxItemPerPage } from '../../../common/utils/file.utils';
import { TablePagination } from 'legacy-components/ui/table/table-pagination';
import { useCreateTablePaginationProps } from 'common/utils/create-table-pagination-props.hook';
import { Notification } from 'services/notification.service';
import { getNewCurrentPage } from 'helpers/pagination';
import { ConfirmModal } from 'components/atoms/ConfirmModal/ConfirmModal';

export const LeaseDocuments = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>('');
  const { data: leaseDocumentsData, isLoading: isLeaseDocumentsLoading } = useGetLeaseDocuments({
    page: currentPage,
    perPage: maxItemPerPage,
  });

  const updateCurrentPage = () => {
    const newCurrentPage = getNewCurrentPage(leaseDocumentsData?.totalCount || 0, maxItemPerPage, currentPage);
    if (newCurrentPage !== currentPage) setCurrentPage(newCurrentPage);
  };

  const { mutateAsync: deleteLeaseDocument, isPending } = useDeleteLeaseDocument({
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The lease document has been deleted.');
      updateCurrentPage();
    },
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message || 'Sorry, an error occurred, the file could not be deleted');
    },
  });

  const paginationCallback = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const tablePaginationProps = useCreateTablePaginationProps(
    currentPage,
    leaseDocumentsData?.totalCount,
    maxItemPerPage,
    paginationCallback,
  );

  const [openPreviewModal, togglePreviewModal] = useState(false);
  const [openAddDocumentModal, toggleAddDocumentModal] = useState(false);

  const [previewFileUrl, setPreviewFileUrl] = useState<string>('');

  const viewDocumentByUrl = (fileUrl: string) => {
    togglePreviewModal(true);
    setPreviewFileUrl(fileUrl);
  };

  return (
    <>
      <AddLeaseDocument open={openAddDocumentModal} onClose={() => toggleAddDocumentModal(false)} />
      <ConfirmModal
        open={confirmModalOpen}
        onOk={async () => {
          await deleteLeaseDocument(selectedItemId);
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
        title='Do you want to delete your lease document?'
        isLoadingSubmitBtn={isPending}
      />
      <PreviewLeaseDocument open={openPreviewModal} onClose={() => togglePreviewModal(false)} file={previewFileUrl} />
      <div className='w-full text-black flex flex-col gap-4 md:gap-[50px] pb-7'>
        <div className='w-full shadow-white-xl px-4 py-4 md:px-[34px] md:py-[28px]'>
          <div className='flex flex-col gap-4'>
            <div className='flex items-center justify-between gap-4'>
              <div className='title text-xl font-bold'>{LEASE_DOCUMENTS}</div>
              <button
                onClick={() => toggleAddDocumentModal(true)}
                className='flex items-center justify-center border border-trueGray border-opacity-50 rounded-lg p-2 gap-2'
              >
                <PlusOutlined />
                <p className='title text-sm font-bold'>{ADD_LEASE_DOCUMENT_BTN}</p>
              </button>
            </div>
            <div className={'flex flex-col gap-3'}>
              {isLeaseDocumentsLoading ? (
                <Loader />
              ) : leaseDocumentsData?.totalCount === 0 ? (
                <div className='flex items-center justify-center p-3'>
                  <p className='text-trueGray font-bold'>{EMPTY_LIST}</p>
                </div>
              ) : (
                <>
                  {leaseDocumentsData?.items?.map((leaseDocument) => (
                    <UploadedLeaseDocument
                      key={leaseDocument.fileId}
                      document={leaseDocument}
                      openModal={() => viewDocumentByUrl(leaseDocument.fileUrl)}
                      deleteLeaseDocument={() => {
                        setSelectedItemId(leaseDocument?.id);
                        setConfirmModalOpen(true);
                      }}
                    />
                  ))}
                  <TablePagination {...tablePaginationProps} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
