import { Box, Typography, Tooltip } from '@mui/material';
import { CreditScoreEnum, IntelligenceLevelEnum } from './credit-score-section.enum';
import { scoreColors } from './credit-score-section.styles';

interface Props {
  creditScore?: number;
  // intelligenceLevel?: string;
  intelligenceLevel?: IntelligenceLevelEnum;
  isPassed?: boolean;
  isBlurred?: boolean;
}

const min = 300;
const max = 850;
export default function CreditScoreReportBar({ creditScore = min, isBlurred = false, intelligenceLevel = IntelligenceLevelEnum.Silver, isPassed = true }: Props) {
  
  const adjustedValue = Math.max(min, Math.min(max, creditScore));

  // NOTE: Calculate the percentage position of the value on the bar
  const valuePercentage = ((adjustedValue - min) / (max - min)) * 100;

  const getScoreColor = () => {
    if (adjustedValue <= CreditScoreEnum.Red) {
      return scoreColors[CreditScoreEnum.Red];
    } else if (adjustedValue >= CreditScoreEnum.Yellow && adjustedValue < CreditScoreEnum.LightGreen) {
      return scoreColors[CreditScoreEnum.Yellow];
    } else if (adjustedValue >= CreditScoreEnum.LightGreen && adjustedValue < CreditScoreEnum.Green) {
      return scoreColors[CreditScoreEnum.LightGreen];
    } else if (adjustedValue >= CreditScoreEnum.Green && adjustedValue < CreditScoreEnum.DarkGreen) {
      return scoreColors[CreditScoreEnum.Green];
    } else {
      return scoreColors[CreditScoreEnum.DarkGreen];
    }
  }

  let scoreColor = getScoreColor();
  
  return (
    <Box sx={{ width: '100%', maxWidth: 400, mt: 3.5 }}>
      <Box
        sx={{
          display: 'flex',
          height: 24,
          position: 'relative',
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ width: '51.6%', backgroundColor: `${scoreColors[CreditScoreEnum.Red]}`, marginRight: '4px', borderRadius: '4px' }}></Box>
        <Box sx={{ width: '16%', backgroundColor: `${scoreColors[CreditScoreEnum.Yellow]}`, marginRight: '4px', borderRadius: '4px' }}></Box>
        <Box sx={{ width: '12.3%', backgroundColor: `${scoreColors[CreditScoreEnum.LightGreen]}`, marginRight: '4px', borderRadius: '4px' }}></Box>
        <Box sx={{ width: '11.3%', backgroundColor: `${scoreColors[CreditScoreEnum.Green]}`, marginRight: '4px', borderRadius: '4px' }}></Box>
        <Box sx={{ width: '8.8%', backgroundColor: `${scoreColors[CreditScoreEnum.DarkGreen]}`, borderRadius: '4px' }}></Box>
      </Box>

      {!isBlurred && <Box
        sx={{
          position: 'relative',
          height: 0,
          marginTop: '-61px', // NOTE: Adjusts position above the bar
        }}
      >
        <Tooltip title={`Credit Score: ${adjustedValue}`} arrow placement="top">
          <Box
            sx={{
              position: 'absolute',
              left: `${valuePercentage}%`,
              transform: 'translateX(-50%)',
              backgroundColor: scoreColor,
              padding: '4px 8px',
              borderRadius: '4px',
              color: 'white',
              fontWeight: '700',
              zIndex: 2,
              cursor: 'pointer',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-4px',
                left: '50%',
                color: scoreColor,
                transform: 'translateX(-50%)',
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: `8px solid ${scoreColor}`,
              }
            }}
          >
            {adjustedValue}
          </Box>
        </Tooltip>
      </Box>}
      {!isBlurred && <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '61px' }}>
        <Typography color="#F06565" fontSize={'12px'} fontWeight={700}>
          300
        </Typography>
        <Typography color="#327461" fontSize={'12px'} fontWeight={700}>
          850
        </Typography>
      </Box>}
    </Box>
  );
}