import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'components/organisms/Modal';
import { CheckoutForm } from 'components/organisms/CheckoutForm/CheckoutForm';
import { ENV } from 'common/enums/enums';
import { Elements } from '@stripe/react-stripe-js';
import { ScreeningServicePlanPayment } from 'common/types/services/api/tenant-screening';
import { Loader } from 'legacy-components/componets';


type PayTenantBackgroundScreeningDialogProps = {
    title: string,
    promptText?: string,
    isOpen: boolean,
    isLoading: boolean,
    data?: ScreeningServicePlanPayment,
    onClose?: () => void,
    onSuccess?: () => void,
};

const SimpleLoader = ({ prompt }) => {
    return (
      <div className='px-4 w-full min-h-[380px]'>
        <Loader className='h-full opacity-75 bg-white my-8' prompt={prompt} />
      </div>
    );
};

const PayTenantBackgroundScreeningDialog: React.FC<PayTenantBackgroundScreeningDialogProps> = (props) => {
    const { title, promptText, isOpen, isLoading, data, onSuccess, onClose } = props;
    
    const promptTextDefault = "Please enter your card details in a form below to proceed with payment";
    const successMessage = "Thank you! We're now processing your payment. It could take a time";

    const stripePromise = loadStripe(ENV.REPLENISH_PUBLIC_KEY);
    const [isStripeLoading, setIsStripeLoading] = React.useState<boolean>(false);
    
    const stripeOptions = {
        clientSecret: data?.paymentKey
    };

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className="flex flex-col gap-y-4">
                <div className="p-1 bg-zinc-50 opacity-75 rounded text-center">
                    <span className="text-small text-zinc-500">{
                        promptText ?? promptTextDefault
                    }</span>   
                </div>
                <div className="my-4 m-auto">                 
                    {  !isLoading && data?.paymentKey ? (
                            <Elements stripe={stripePromise} options={stripeOptions}>
                                <CheckoutForm 
                                    clientSecret={stripeOptions.clientSecret}
                                    paidSum={data?.amount}
                                    isFormDisabled={typeof data?.amount === 'undefined'}
                                    successMessage={successMessage}
                                    isStripeLoading={isStripeLoading}
                                    setIsStripLoading={setIsStripeLoading}
                                    setPaymentDone={onSuccess}
                                    onCloseModal={onClose}                            
                                />
                            </Elements>
                        ) : (
                            <SimpleLoader prompt={'Loading payment data...'} />
                        ) 
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PayTenantBackgroundScreeningDialog;