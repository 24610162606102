import React from 'react';
import { Button, Loader } from 'legacy-components/componets';
import { Modal } from 'components/organisms/Modal';
import ScreeningServicePlanList from 'components/molecules/ScreeningServicePlan/ScreeningServicePlanList';
import { ScreeningServicePlanCard } from 'components/molecules/ScreeningServicePlan/card';
import { ScreeningServicePlan } from 'common/types/services/api/tenant-screening';

type TenantScreeningServicePlanProps = {
    title: string,
    promptText?: string,
    isOpen: boolean,
    isLoading: boolean,
    data?: ScreeningServicePlan[],
    onClose: () => void,
    onSelect: (e: any, idx: number | string) => void
};

const AdInlineComponent = (props) => (
    <p className="rounded-full px-[11px] py-1 bg-primary absolute top-[-12px] 
        text-center text-white text-[10px] font-bold"> {props?.title}
    </p>
);

const SimpleLoader = ({ prompt }) => {
    return (
      <div className='px-4 w-full min-h-[400px]'>
        <Loader className='h-full opacity-75 bg-white my-8' prompt={prompt} />
      </div>
    );
};

const TenantScreeningServicePlan: React.FC<TenantScreeningServicePlanProps> = (props) => {
    const { title, promptText, isOpen, isLoading, data, onClose, onSelect } = props;
    const promptTextDefault = 'Please select service plan to start Background Check process over the applicants';
    
    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div className="flex flex-col gap-y-2">
                <div className="p-1 bg-zinc-50 opacity-75 rounded text-center">
                    <span className="text-small text-zinc-500">{
                        promptText ?? promptTextDefault
                    }</span>   
                </div>
                <div className="px-2">                   
                {
                    !isLoading && data ? ( 
                        <ScreeningServicePlanList>
                            { data.map((plan, index) => (
                                <ScreeningServicePlanCard 
                                    key={index}
                                    data={plan} 
                                    adComponent={index === 1 ? (<AdInlineComponent title="Best value" />) : null}
                                    actionComponent={(<Button label='Start screening' onClick={(e) => onSelect(plan, index)} />)} 
                                />
                            ))}
                        </ScreeningServicePlanList>
                    ) :
                    (
                        <SimpleLoader prompt={'Loading data...'} />
                    )
                }  
                </div>
                <div className="py-2 m-auto">
                    <Button label="Cancel" onClick={onClose} theme="red-outline"/>
                </div>
            </div>
        </Modal>
    );
}

export default TenantScreeningServicePlan;
