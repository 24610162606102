import { PaymentbillingPath } from 'common/enums/services/api/paths/payment-api-path';
import { axiosInstance } from './axiosInstance';
import { PaymentCardsDto } from 'common/types/services/api/payment/payment-cards-dto.type';
import { UpdatePaymentDetailsRequestDto } from 'common/types/services/api/payment/update-payment-details-request-dto.type';
import { VerificationPaymentDto } from 'common/types/services/api/payment/verification-payment-dto.type';
import { VerificationPaymentRequest } from 'common/types/services/api/payment/verification-payment-request.type';
import { ScreeningServicePlanPaymentPayload, ScreeningServicePlanPayment } from 'common/types/services/api/tenant-screening';

export const cancelSubscription = (estateId: string): Promise<void> => {
  return axiosInstance.delete(`${PaymentbillingPath.PAYMENT_ESTATE_PATH}/${estateId}`);
};

export const getPaymentCards = (): Promise<PaymentCardsDto> => {
  return axiosInstance.get(`/billings/payments/card`);
};

export const deletePaymentCard = (id: string): Promise<void> => {
  return axiosInstance.delete(`/billings/payments/card/${id}`);
};

export const createNewPaymentMethod = (): Promise<{ secret: string }> => {
  return axiosInstance.post(`/billings/payments/card`);
};

export const updatePaymentDetails = (data: UpdatePaymentDetailsRequestDto): Promise<void> => {
  const { id, ...restData } = data;
  return axiosInstance.put(`/billings/payments/estate/${id}`, restData);
};

export const getVerificationPayment = (verificationId?: string | null): Promise<VerificationPaymentDto> => {
  return axiosInstance.get(`${PaymentbillingPath.PAYMENT_VERIFICATION_PATH}?_vid=${verificationId}`);
};

export const requestVerificationPayment = (data: VerificationPaymentRequest | null): Promise<VerificationPaymentDto> => {
  return axiosInstance.post('/billings/payments/verification', data);
};

export const updateVerificationPayment = (id: string | undefined): Promise<VerificationPaymentDto> => {
  return axiosInstance.put(`/billings/payments/verification/${id}`);
};

export const requestBackgroundCheckPayment = (payload: ScreeningServicePlanPaymentPayload): Promise<ScreeningServicePlanPayment> => {
  return axiosInstance.post('/billings/payments/background-check', payload);
};
