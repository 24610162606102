import { RentalPartner } from "legacy-pages/renter/profile/renter-profile/renter-profile-information/renter-personal-details-form/types";
import { isPersonAdult } from "./person-age.helpers";

type OccupantsInfo = {
  adultsNumber: number;
  childrenNumber: number;
}

export const getRentalPartnersAgeInfo = (rentalPartners: RentalPartner[]) : OccupantsInfo => {
  let adultsNumber = 0;
  let childrenNumber = 0;

  rentalPartners.forEach(x => {
    if (isPersonAdult(x.dateOfBirth)) {
      adultsNumber++;
    } else {
      childrenNumber++;
    }
  });

  return { adultsNumber, childrenNumber };
}