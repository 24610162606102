export enum CreditScoreEnum {
  Red = 579,
  Yellow = 580,
  LightGreen = 670,
  Green = 740,
  DarkGreen = 800
}

export enum IntelligenceLevelEnum {
  Poor = 1,
  Silver = 2,
  Gold = 3,
  Platinum = 4,
  Diamond = 5
}