import {
  UndefinedInitialDataOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import {
  CreateLeaseDocument,
  LeaseDocumentResponse,
  SendLeaseDocument,
} from 'common/types/services/api/landlord-lease-documents/landlord-lease-documents.types';
import { PaginationDtoRequest } from 'common/types/types';
import { createLeaseDocument, deleteLeaseDocument, getLeaseDocuments, sendLeaseDocuments } from 'services/api/lease-documents';
import { Notification } from 'services/notification.service';

export function useGetLeaseDocuments(
  params: PaginationDtoRequest,
  options?: Omit<
    UndefinedInitialDataOptions<LeaseDocumentResponse, Error, LeaseDocumentResponse>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: ['lease-documents', params],
    queryFn: () => getLeaseDocuments(params),
    ...options,
  });
}

export function useCreateLeaseDocument(options?: UseMutationOptions<string[], AxiosError<Error>, CreateLeaseDocument[], unknown>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: createLeaseDocument,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message || 'Sorry, an error occurred, the file could not be added');
    },
    onSuccess: (data, variables, context) => {
      const notification = new Notification();
      notification.success('The lease document has been created.');
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['lease-documents'] });
    },
  });
}

export function useDeleteLeaseDocument(options?: UseMutationOptions<boolean, Error, string, unknown>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: deleteLeaseDocument,
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context);
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['lease-documents'] });
    },
  });
}

export function useSendLeaseDocument(options?: UseMutationOptions<void, AxiosError<BackendError>, SendLeaseDocument, unknown>) {
  return useMutation({
    ...options,
    mutationFn: sendLeaseDocuments,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message || 'Sorry, an error occurred, the file could not be sent');
    },
    onSuccess: (data, variables, context) => {
      const notification = new Notification();
      notification.success('The lease document has been sent.');
      options?.onSuccess?.(data, variables, context);
    }
  });
}
