import { CellProps } from "react-table";
import { dateFormatMMMM_DD, dateTimeFormatHHMM_A } from "helpers/date";
import { OfferDateCellProps, OffersTableRow } from '../common/types/OfferTable.types';

const OfferDateCell = <T extends object = OffersTableRow, V extends Date = OfferDateCellProps>({ value }: CellProps<T, V>) => {
    return (
        <div className="flex flex-col text-base text-trueGray gap-0.5 pl-2.5">
            <span>{`${dateFormatMMMM_DD(value)},`}</span>
            <span>{dateTimeFormatHHMM_A(value)}</span>
        </div>
    )
};

export { OfferDateCell };