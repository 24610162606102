export enum RenterAccountSideBarItems {
  ProfileInformation = 'profileInformation',
  UpcomingTours = 'upcomingTours',
  TourHistory = 'tourHistory',
  Offers = 'offers',
  SavedSearch = 'savedSearch',
  LeaseDocuments = 'leaseDocuments',
  // Favorites = 'favorites',
  // Referral = 'referral',
  Security = 'security',
}

export enum RenterAccountNavItems {
  ProfileInformation = 'profileInformation',
  UpcomingTours = 'upcomingTours',
  TourHistory = 'tourHistory',
  Offers = 'offers',
  SavedSearch = 'savedSearch',
  LeaseDocuments = 'leaseDocuments',
  // Favorites = 'favorites',
  // Referral = 'referral',
  Security = 'security',
}
