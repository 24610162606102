import { CellProps } from 'react-table';
import {
  OfferStatusCellProps,
  OffersTableRow,
} from 'legacy-pages/renter/profile/renter-profile/offers/common/types/OfferTable.types';
import { OfferStatus } from 'components/atoms/OfferStatus/OfferStatus';

export const OfferStatusCell = <T extends object = OffersTableRow>({ value }: CellProps<T, OfferStatusCellProps>) => {
  return <OfferStatus offerStatus={value} />;
};
