import { dateFormatMMM_D_YYYY } from 'helpers/date';
import { Divider } from 'legacy-components/componets';
import { ApplicationPropertyDetailsProps } from '../common/types/types';
import { useState } from 'react';
import { RenterDocumentsModal } from './renter-documents-modal';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { ApplicationPropertyItemProps } from '../common/types/applications-summary.type';
import { formatPriceValue } from 'helpers/string.helpers';

const ApplicationPropertyItem = ({ title, value }: ApplicationPropertyItemProps) => {
  const { isMobile } = useWindowSize();
  return (
    <div className={`${!isMobile ? '' : 'flex justify-between'} min-w-[150px]`}>
      <div className='text-base font-medium mb-1'>{title}</div>
      <div className='text-lg font-bold'>{`${value}`}</div>
    </div>
  );
};

const ApplicationPropertyDetails = ({
  totalIncome,
  incomeToRent,
  adultOccupants,
  childrenOccupants,
  moveInDate,
  detailsMessage,
  proposedRent
}: ApplicationPropertyDetailsProps) => {
  const [renterDocumentsModalOpen, setRenterDocumentsModalOpen] = useState(false);
  const { isMobile } = useWindowSize();
  return (
    <div className='details p-6 border-[1.5px] border-gray flex flex-col gap-6 rounded-lg'>
      <RenterDocumentsModal open={renterDocumentsModalOpen} onClose={() => setRenterDocumentsModalOpen(false)} />
      <div className={`flex gap-4 flex-wrap ${isMobile ? 'flex-col' : 'justify-between'}`}>
        <ApplicationPropertyItem title='Total Income' value={`${formatPriceValue(totalIncome)}/mo`} />
        <ApplicationPropertyItem title='Rent Offer' value={`${formatPriceValue(proposedRent)}/mo`} />
        <ApplicationPropertyItem title='Income to Rent' value={`${formatPriceValue(incomeToRent)}X`} />
        <ApplicationPropertyItem title='Adult (18 yrs old +)' value={`${adultOccupants} person`} />
        <ApplicationPropertyItem title='Children (0-18 yrs old)' value={`${childrenOccupants} person`} />
        <ApplicationPropertyItem title='Move-in Date' value={dateFormatMMM_D_YYYY(moveInDate)} />
      </div>
      <Divider />
      <div className='flex flex-col gap-1 text-base'>
        <div className='font-bold'>Personal Message</div>
        <p>{detailsMessage}</p>
      </div>
      {/* <Divider /> */}
      {/* <Button
        startIcon={<FileTextOutlined className={'text-sky-300'} />}
        label={`View Renter's Documents`}
        className={'w-full px-4 py-2.5 bg-white rounded-lg border border-sky-100 text-sky-300'}
        onClick={() => setRenterDocumentsModalOpen(true)}
      /> */}
    </div>
  );
};

export { ApplicationPropertyDetails };
