import { OffersItemDto } from 'common/types/services/api/offers/offer-item-dto.type'
import { isNotEmptyString } from 'common/utils/check-empty-string'
import { NotificationTypeIcon } from 'legacy-components/componets'

export const WARNING_MSG = 'This offer was rejected';
export const REASON = 'Rejection reason:';
export const REASON_NOT_EXISTS = 'You did not provide a reason for rejection.';

interface Props {
  offerDetails: OffersItemDto
}
const OfferRejectedReason = ({ offerDetails }: Props) => {
  return (
    <>
      <div className='flex p-2 items-center justify-start gap-3 rounded-xl bg-secondary w-max'>
        <NotificationTypeIcon type='error' />
        <div className='text-base text-error'>{WARNING_MSG}</div>
      </div>
      {
        <div className='flex flex-col p-2 items-start'>
          <div className='text-base font-bold mb-2'>{REASON}</div>
          <div className='text-base border border-primary w-full pl-4 p-2 rounded-lg text-wrapper text-ellipsis overflow-hidden ...'>
            {isNotEmptyString(offerDetails?.rejectReason) ? offerDetails?.rejectReason : REASON_NOT_EXISTS}
          </div>
        </div>
      }
    </>
  )
}

export default OfferRejectedReason