import { useParams } from 'react-router-dom';
import { Loader } from 'legacy-components/componets';
import { useOfferDetails } from 'hooks/query';
import ApplicationSummaryContainer from './components/ApplicationSummaryContainer';

const ApplicationSummary = () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { data: offerDetails, isLoading: isOfferDetailsLoading } = useOfferDetails(applicationId);

  if (isOfferDetailsLoading) {
    return <Loader />;
  }

  return (
    <ApplicationSummaryContainer applicationId={applicationId} offerDetails={offerDetails} />
  );
};

export { ApplicationSummary };
