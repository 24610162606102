import { ApplicantSignature } from '../common/types/master-agreement.type';
import { useNavigate, useParams } from 'react-router-dom';
import { dateFormatMMMM_D_YYYY, dateFormatMMMM_DD_YYYY_HH_MM_A, dateFormatYYYY_MM_DD } from 'helpers/date';
import { useGetMasterAgreement, useSignMasterAgreement } from 'hooks/query/use-master-agreement';
import { Button, Loader } from 'legacy-components/componets';
import { useAuth } from 'hooks';
import { useEffect, useState } from 'react';
import { useUnverifiedProfileActionGuard } from 'hooks/use-unverified-profile-guard';
import { AppRoute } from 'common/enums/enums';
import React from 'react';
import ConsentLeasesModal from '../consent-leases-modal/ConsentLeasesModal';
import { useGetSentLeaseDocuments, useSignSentLeases } from 'hooks/query/use-renter-lease-documents';

const MasterAgreement = () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [applicantSignature, setApplicantSignature] = useState<ApplicantSignature>({ 
    signature: '', 
    signedAt: dateFormatYYYY_MM_DD(new Date()),
    applicationId,
    isSigned: false,
    ssn: ''
  });
  const [openConsentLeasesModal, setOpenConsentLeasesModal] = useState(false);
  const [leaseSentSigned, setLeaseSentSigned] = useState(false);
  const { mutateAsync: signMasterAgreement, isPending: isSendingMasterAgreementSigning } = useSignMasterAgreement();
  const { mutateAsync: consentSentLeases, isPending: isSendingConsentLeasesSigning } = useSignSentLeases();
  const { data: masterAgreementInfo, isLoading: isMasterAgreementLoading } = useGetMasterAgreement(applicationId);
  const { data: leases, isLoading: isSentleaseLoading} = useGetSentLeaseDocuments(applicationId);
  const { user, isAuthenticated } = useAuth();
  const navigate  = useNavigate();

  const { withActionGuard } = useUnverifiedProfileActionGuard({
    modalProps: {
      actionText: 'to sign the lease',
      onClose: () => navigate(AppRoute.PROFILE_INFO)
    },
  });

  const handleMasterAgreementSigning = React.useCallback(() => {
    if (!masterAgreementInfo) return;
    signMasterAgreement({...applicantSignature}).then(() => setApplicantSignature({...applicantSignature, isSigned: true}));
  }, [masterAgreementInfo, withActionGuard]);

  const handleConsentLeases = React.useCallback(() => {
    consentSentLeases({ applicationId }).then(() => { setOpenConsentLeasesModal(false); setLeaseSentSigned(true); });

  }, [masterAgreementInfo, withActionGuard]);

  const isSentLeasesNotSigned = () => leases?.some(x => !x.isSigned);

  useEffect(() => {
    if (isSentleaseLoading) return;

    if (isSentLeasesNotSigned()) {
      setOpenConsentLeasesModal(true);
      setLeaseSentSigned(false);
    } else {
      setLeaseSentSigned(true);
    }
  }, [leases]);

  useEffect(() => {
    if (!masterAgreementInfo || !user) return;
    const applicant = masterAgreementInfo.applicants?.find(x => x.userId === user.id);

    setApplicantSignature({...applicantSignature, 
      signature: applicant?.signature || '', 
      signedAt: dateFormatYYYY_MM_DD(applicant?.signedAt) || dateFormatYYYY_MM_DD(new Date()),
      isSigned: applicant?.isSigned || false,
      ssn: applicant?.ssn || ''
    });
  }, [masterAgreementInfo, user]);

  if (isMasterAgreementLoading) {
    return <Loader />;
  }

  const getFormattedApplicantNamesByAge = (isAdult: boolean) => {
    if (!masterAgreementInfo) return [];
    return masterAgreementInfo.applicants.filter(x => x.isAdult === isAdult).map(x => `${x.firstName} ${x.lastName}`).join(', ');
  }

  if (isSentleaseLoading) {
    return <Loader />
  }

  return (
    <div>
      {openConsentLeasesModal && leases?.length && <ConsentLeasesModal 
        isOpen={openConsentLeasesModal}
        leases={leases}
        onAccept={handleConsentLeases}
        onGoBack={() => navigate(AppRoute.MASTER_AGREEMENTS)}
        isAccepting={isSendingConsentLeasesSigning}
      />}
      <div className='py-[28px] pl-[32px] pr-[42px] text-black'>
        <p className='flex-center text-[32px] font-bold'>RESIDENTIAL LEASE</p>
        <p className='flex-center text-[32px] font-bold'>AGREEMENT/CONTRACT</p>
        <br/>
        <p><i className='font-bold'>This is a LEGALLY binding document. Read carefully before signing.</i></p>
        <p>IF TENANT DOES NOT UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT, LEGAL ADVICE SHOULD BE SOUGHT BEFORE SIGNING.</p>
        <br/>
        <p>
          This Master Lease is incorporated into the Lease Contract of the respective
          Landlord(s) and this agreement/contract as “Lease Contract” or “Lease”) is hereby
          incorporated into and made a part of such full Lease Contract of
          Landlord(s)/Represenatative.
        </p>
        <br/>
        <p>
          <span className='font-bold'>AGREEMENT OF LEASE</span>, between the parties signing this agreement; wherein
          Landlord(s), and Tenant(s) in consideration of the rents herein specified, does
          hereby lease to tenant(s), and tenant(s) does hereby lease the premises, for the
          above Term described as follows:
        </p>
        <br/>
        <p>Date of Lease Contract:  <span className='underline font-bold'>{dateFormatMMMM_D_YYYY(masterAgreementInfo?.moveInDate)}</span></p>
        <br/>
        <div>
          <span className='font-bold'>1. PARTIES.</span> This Lease Contract is between you, the resident(s) (list all people signing the Lease
          Contract): <span className='underline font-bold'>
            {getFormattedApplicantNamesByAge(true)}
          </span>
          <p>and us, the owner (s):</p>
          <p><span className='underline font-bold'>{masterAgreementInfo?.landLordName}</span> (Landlord)</p>
        </div>
        <br/>
        <p>
          You’ve agreed to rent <span className='underline font-bold'>{masterAgreementInfo?.estateAddress}, {masterAgreementInfo?.unitNumber}</span> (property
          address – street, city, state, zip)  (the “apartment” or the “premises”) for use as a
          private residence only. The terms “you” and “your” refer to all residents listed
          above. The terms “we,” “us,” and “our” refer to the owner listed above (or any of
          owner’s successors’ in interest or assigns).  Written or electronic notice to or from
          our managers constitutes notice to or from us. If anyone else has guaranteed
          performance of this Lease Contract, a separate Lease Contract Guaranty for each
          guarantor is attached. Unless otherwise agreed to by both parties in writing, all
          residents listed shall use the apartment as their primary residence during the term
          of the Lease.
        </p>
        <br/>
        <div>
          <span className='font-bold'>2. OCCUPANTS.</span> The apartment will be occupied only by you and (list all other
          occupants who are under 18 and not required to sign the Lease):
          <p className='underline font-bold'>
            {getFormattedApplicantNamesByAge(false)}
          </p>
          Maximum number of Occupants: <span className='underline font-bold'>{masterAgreementInfo?.applicants.length}</span>.  No one else may occupy the
          apartment. Persons not listed above must not stay in the apartment for more than <span className='font-bold'>7</span> consecutive days without our prior written consent.
        </div>
        <br/>
        <p>
          <span className='font-bold'>3. LEASE TERM.</span> The initial term of the Lease Contract begins on the <span className='underline font-bold'>{dateFormatMMMM_D_YYYY(masterAgreementInfo?.moveInDate)}</span>,
          and ends on <span className='underline font-bold'>{dateFormatMMMM_D_YYYY(masterAgreementInfo?.moveOutDate)}</span>. Time to be
          specified by Landlords in full Lease agreement.
        </p>
        <br/>
        <p><span className='font-bold'>4. RENT:</span> Equal Monthly Installment Payments of: <span className='font-bold'>$</span><span className='underline font-bold'>{masterAgreementInfo?.monthlyPayment}</span></p>
        <br/>
        <p>
          <span className='font-bold'>5. SECURITY DEPOSIT.</span> The total security deposit at the time of execution of this Lease Contract
          for all residents in the premises is <span className='font-bold'>$</span><span className='underline font-bold'>{masterAgreementInfo?.securityDeposit}</span> , due
          on the date this Lease Contract is signed.
        </p>
        <br/>
      
        <div>
          <p className='font-bold'>Move in Fees:</p>
          <p className='ml-5'>Rent: <span className='font-bold'>$</span><span className='underline font-bold'>{masterAgreementInfo?.monthlyPayment}</span></p>
          <p className='ml-5'>Security Deposit: <span className='font-bold'>$</span><span className='underline font-bold'>{masterAgreementInfo?.securityDeposit}</span></p>
          <p className='ml-5'>Fees (Pet, Parking, etc): <span className='font-bold'>$</span><span className='underline font-bold'>{masterAgreementInfo?.fees}</span></p>
        </div>
        <br/>
        <p>
          <span className='font-bold'>CONSENT:</span> By signing below, I/We agree that we have read the agreement.  The
          obligations of Landlord(s) and Resident(s) hereunder are mutual, and all
          understandings and agreements heretofore made between the parties hereto are
          merged into this Lease Master Summary and is a part of the entire Lease of
          Landlord(s).
        </p>
        <br/>
        <div>
          <p className='font-bold'>TENANT(S)</p>
          <p className='font-bold'>Acknowledgment by Tenants:</p>
          I have read, understand and agree with the above Lease Overview Summary.  I
          acknowledge that my lease is a written contract and that I have the responsibility
          to read it and ask necessary questions before I sign it. I acknowledge that I have
          had the opportunity to do so. I also acknowledge that the owner’s agents and
          representatives will provide a full lease to sign prior to moving in.  This
          acknowledgement cannot be altered or enlarged by oral agreement.
        </div>
        <br/>
        <p className='font-bold text-[12px] flex-center text-center'>
          You are legally bound by this document. Please read it carefully.
        </p>
        <p className='font-bold text-[12px] flex-center text-center'>
          Before submitting a rental application or signing a Lease Contract, you may take a copy of these documents to review
          and/or consult an attorney.  Additional provisions or changes may be made in the Lease Contract if agreed to in
          writing by all parties.
        </p>
        <br/>
        <div>
          <p className='font-bold'>CERTIFICATION OF ACCURACY:</p>
          <p>
            The following parties have reviewed the information above and certify, to the best of their knowledge, that the information provided by the signatory is true and accurate.
          </p>
        </div>
        <div className='space-y-4 my-6'>
          {isAuthenticated && masterAgreementInfo?.applicants.filter(x => x.userId !== user?.id && x.isAdult).map(applicant => (
            <div key={applicant.id} className='flex space-x-2 border-b-2 pb-2'>
              <div className='w-1/3'>
                  <p className='block font-medium'>Tenant</p>
                  <p>{`${applicant?.firstName} ${applicant?.lastName}`}</p>
              </div>
              <div className='w-1/3'>
                  <p className='block font-medium'>Date</p>
                  <p>{dateFormatMMMM_DD_YYYY_HH_MM_A(applicant?.signedAt)}</p>
              </div>
              <div className='w-1/3'>
                  <p className='block font-medium'>Soc. Sec. #</p>
                  <p>{applicant?.isSigned ? applicant?.ssn : ''}</p>
              </div>
            </div>
          ))}
          <div>TODO: SECTION FOR LANDLORD COUNTER SIGNATURE</div>
          {/* TODO: ADD LANDLORD SIGN - will be added later - Need to update MasterAgreement table with LL data (LLSignature, LLSignedAt, LLSsn, etc.) */}
        </div>
        <div className='flex flex-col gap-10'>
          <div className='flex items-center justify-between mt-12'>
            <div className='flex text-[14px] gap-x-2 relative'>
                <label className='font-bold text-trueGray'>Applicant's signature:</label>
                <input
                    name='applicantSignature'
                    type='text'
                    className='w-[150px] text-4 border-b border-gray-200 focus:outline-none'
                    disabled={applicantSignature.isSigned || !leaseSentSigned}
                    value={applicantSignature.signature || ''}
                    onChange={(e) => setApplicantSignature({...applicantSignature, signature: e.target?.value})}
                />
            </div>
            <div className='flex text-[14px] gap-x-2 relative'>
              <label className='font-bold text-trueGray'>Applicant's SSN:</label>
              <input
                  name='applicantSsn'
                  type='text'
                  className='w-[150px] text-4 border-b border-gray-200 focus:outline-none'
                  value={applicantSignature.ssn || ''}
                  readOnly
              />
            </div>
            <div className='flex text-[14px] gap-x-2'>
                <label className='font-bold text-trueGray'>Signed at:</label>
                <input
                    name='signedAt'
                    type='date'
                    value={applicantSignature.signedAt || ''}
                    readOnly
                    className='w-[135px] border-b border-gray-200 focus:outline-none'
                />
            </div>
          </div>
        </div>
      </div>
      {leaseSentSigned && <div className='flex justify-end pl-[32px] pr-[42px]'>
        <Button
          label='Accept'
          theme='primary'
          disabled={!applicantSignature.signature || isSendingMasterAgreementSigning || applicantSignature.isSigned}
          onClick={() => withActionGuard(() => handleMasterAgreementSigning())}
        />
      </div>}
    </div>
  )
}

export { MasterAgreement };
