import { DownloadOutlined, EyeOutlined, FileFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { DocumentViewerDrawer } from 'components/organisms/DocumentViewer/DocumentViewer';
import { useGetRenterDocsForOffer } from 'hooks/query/offers/use-get-renter-docs';
import { Button, Loader } from 'legacy-components/componets';
import { downloadFileByUrl } from 'legacy-pages/common/utils/file.utils';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  title: string;
}

export const ApplicationPropertyRenterDocumentsDetails = ({ title }: Props) => {
  const { applicationId } = useParams();
  const [bankStatementModalOpen, setBankStatementModalOpen] = useState(false);
  const [selectedBankStatement, setSelectedBankStatement] = useState<{ fileName: string; fileUrl: string } | null>(
    null,
  );
  const { data: renterDocs, isLoading: isLoadingDocs } = useGetRenterDocsForOffer(applicationId || '', {
    enabled: !!applicationId,
  });
  return (
    <div className='details p-6 border-[1.5px] border-gray flex flex-col gap-3 rounded-lg'>
      {selectedBankStatement && (
        <DocumentViewerDrawer
          file={selectedBankStatement.fileUrl}
          open={bankStatementModalOpen}
          onClose={() => {
            setBankStatementModalOpen(false);
          }}
          title={`View Renter's Documents`}
        />
      )}
      <div className={'text-sky-600 text-2xl font-bold leading-normal'}>
        <p>{title}</p>
        <Divider />
      </div>
      {isLoadingDocs ? (
        <Loader />
      ) : (
        <div className={'flex flex-col gap-4'}>
          {renterDocs?.map((file) => (
            <div key={file.fileName} className={'justify-between items-center gap-4 flex flex-wrap'}>
              <div className={'flex gap-4 items-center max-w-full'}>
                <div
                  className={
                    'md:min-w-[61.19px] md:w-[61.19px] md:h-[61.19px] min-w-[40px] w-[40px] h-[40px] bg-sky-300 rounded-[116.85px] justify-center items-center flex'
                  }
                >
                  <FileFilled className={'text-sky-50 md:text-[30.81px] text-[20px]'} />
                </div>
                <div
                  className={
                    'text-sky-600 text-xl font-bold leading-normal truncate text-ellipsis overflow-hidden'
                  }
                >
                  {file.fileName}
                </div>
              </div>
              <div className={'flex gap-4 md:w-auto w-full'}>
                <Button
                  label={'View'}
                  startIcon={<EyeOutlined className={'text-[18px]'} />}
                  className={'md:w-[125px] h-[66px] px-8 py-3 grow'}
                  onClick={() => {
                    setBankStatementModalOpen(true);
                    setSelectedBankStatement(file);
                  }}
                />
                <button className={'text-primary text-[30px]'} onClick={() => downloadFileByUrl(file.fileUrl)}>
                  <DownloadOutlined />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
