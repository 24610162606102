import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { QueryKey } from 'common/enums/query-key';
import { ScreeningServicePlan } from 'common/types/services/api/tenant-screening';
import { getOrderPlans } from 'services/api/background-check-api.service';


export default function useGetOrderPlans(
    location: string,
    options?: Omit<
      UndefinedInitialDataOptions<ScreeningServicePlan[], Error, ScreeningServicePlan[], string[]>,
      'queryFn' | 'queryKey'
    >,
  ) {
    return useQuery({
      queryKey: [QueryKey.TenantScreening, 'orders', location],
      queryFn: () => getOrderPlans(location),
      ...options,
    });
  }
  