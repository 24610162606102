import { ReactNode } from 'react'
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';

interface Props {
  isBlurred: boolean;
  blurredTitle: string;
  children: ReactNode;
  styles?: string;
  blurLevelInPx?: string;
}

const BlurredContainer = ({ blurLevelInPx = '8px', children, isBlurred, styles = '', blurredTitle }: Props) => {
  return (
    <Box className={clsx('relative', styles)}>
        <Box sx={{filter: isBlurred ? `blur(${blurLevelInPx})` : 'none', transition: 'filter 0.3s ease-in-out'}}>
          {children}
        </Box>
        {isBlurred && <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem'
          }}>
          <Typography sx={{backgroundColor: '#F1FBFF', padding: '0.5rem'}}>
            {blurredTitle}
          </Typography>
        </Box>}
    </Box>
  )
}

export default BlurredContainer