import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { SelectAuthFlow, SignUpLandlordForms, SignUpRenterForm } from 'legacy-pages/pages';
import { SignUpPhoneConfirmationForm } from 'legacy-pages/auth/sign-up/sign-up-phone-confirmation-form';
import { SignUpPartnerForm } from 'legacy-pages/auth/sign-up-partner-form/sign-up-partner-form';

const SignInPage = lazy(() => import('legacy-pages/auth/sign-in/sign-in'));
const TermsPage = lazy(() => import('legacy-pages/Terms/Terms'));
const PrivacyPolicy = lazy(() => import('legacy-pages/PrivacyPolicy/PrivacyPolicy'));
const SignUpConfirm = lazy(() => import('legacy-pages/auth/sign-up/confirm'));
const ForgotPassword = lazy(() => import('legacy-pages/auth/forgot-password/forgot-password'));
const ResetPassword = lazy(() => import('legacy-pages/auth/change-password/reset-password'));
const TwoFactorAuthentication = lazy(() => import('components/templates/Login/TwoFactorAuthentication'));

export const authRoutes = [
  { path: '', element: <Navigate to='sign-in' replace /> },
  {
    path: 'sign-in',
    element: <Outlet />,
    children: [
      { path: '', element: <SignInPage />, index: true },
      { path: 'two-factor-authentication', element: <TwoFactorAuthentication /> },
    ],
  },
  {
    path: 'sign-up',
    element: <Outlet />,
    children: [
      { path: '', element: <SelectAuthFlow />, index: true },
      { path: 'renter', element: <SignUpRenterForm /> },
      { path: 'partner', element: <SignUpPartnerForm /> },
      { path: 'landlord', element: <SignUpLandlordForms /> },
      { path: 'phone-confirmation', element: <SignUpPhoneConfirmationForm /> },
    ],
  },
  { path: 'sign-up/confirm', element: <SignUpConfirm /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'forgot-password/:token', element: <ResetPassword /> },
  { path: 'terms-of-service', element: <TermsPage /> },
  { path: 'payment-terms-of-service', element: <TermsPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicy /> },
];
