import { Checkbox, Typography, Box } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { colors } from 'colors';
import { DownloadOutlined } from '@mui/icons-material';
import { downloadFileByUrl } from 'legacy-pages/common/utils/file.utils';

interface Props {
  fileName: string;
  fileUrl?: string;
  name?: string;
  showIcon?: boolean;
  disabled?: boolean;
  withCheckbox?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPreview?: () => void;
}

const SentLeaseDocument = ({ fileName, fileUrl, onPreview, name, onChange, showIcon = true, withCheckbox = false, disabled = false, checked = false }: Props) => {
  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      borderRadius: '8px',
      backgroundColor: disabled ? '#F0F0F0' : '#F1FBFF',
      color: disabled ? 'text.disabled' : colors.primaryDark,
      marginBottom: '8px'
    }}>
      {showIcon && <InsertDriveFileIcon 
        sx={{ 
          marginRight: '8px',
          width: '30px',
          height: '30px',
          backgroundColor: colors.primary,
          borderRadius: '50%',
          padding: '5px',
          color: 'white' }} />}
      <Typography sx={{ flex: 1, color: disabled ? 'text.disabled' : colors.primaryDark }}>
        {fileName}
      </Typography>
      <div className='flex items-center gap-4'>
        {fileUrl && <><button onClick={onPreview} type={'button'}>
          <p className={'text-sky-600 text-[14px] font-bold underline'}>Preview</p>
        </button>
        <div className='flex items-center gap-2'>
          <button onClick={() => downloadFileByUrl(fileUrl)} type={'button'}>
            <DownloadOutlined className={'text-primary text-[20px]'} />
          </button>
        </div></>}
        {withCheckbox && <Checkbox
          name={name}
          checked={checked}
          disabled={disabled}
          color="default"
          onChange={onChange}
      />}
      </div>
    </Box>
  );
};

export default SentLeaseDocument