import { useNavigate } from 'react-router-dom';
import { notification } from 'services/services';

//TODO: MAKE FUNCTION MORE SHARED/FLEXIBLE
const useQueryCustomErrorHandler = () => {
  const navigate = useNavigate();

  const onError = (error: any) => {
    const isBadReqError = error?.response?.status === 400;
    const isNotFound = error?.response?.status === 404;
    const isForbidden = error?.response?.status === 403;

    if (isBadReqError) {
      navigate('/not-found');
    } else if(isNotFound) {
      notification.error(error?.response?.data?.detail);
    } else if (isForbidden) {
      console.log('forbidden request');
    } else {
      notification.error('Oops! Something went wrong...');
    }
    return undefined;
  };

  return { onError };
};

const useQueryCustomErrorWithRedirectHandler = () => {
  const navigate = useNavigate();

  const onError = (error: any) => {
    const isBadReqError = error?.response?.status === 400;
    const isNotFound = error?.response?.status === 404;
    const isForbidden = error?.response?.status === 403;

    if (isNotFound) {
      navigate('/not-found');
    } else if(isBadReqError) {
      navigate('/auth/sign-in');
    } else if (isForbidden) {
      navigate('/auth/sign-in');
    } else {
      notification.error('Oops! Something went wrong...');
      navigate('/auth/sign-in');
    }
    return undefined;
  };

  return { onError };
};

export { useQueryCustomErrorHandler, useQueryCustomErrorWithRedirectHandler };
