import { Button, Modal } from 'legacy-components/componets';
import { useEffect, useState } from 'react';
import { CloseOutlined, DownloadOutlined, EyeOutlined, FileFilled } from '@ant-design/icons';
import { useWindowSize } from 'hooks/use-windows-size.hook';
import { useParams } from 'react-router-dom';
import { OfferFileDto } from 'common/types/services/api/offers/offer-file-dto.type';
import { getOfferFiles } from 'services/api/offers-api.service';
import { downloadFileByUrl } from '../../../../common/utils/file.utils';
import { DocumentViewerDrawer } from 'components/organisms/DocumentViewer/DocumentViewer';

type RenterDocumentsModalProps = {
  open: boolean;
  onClose: () => void;
};

const RenterDocumentsModal = ({ onClose, open: openProp }: RenterDocumentsModalProps) => {
  const { isMobile } = useWindowSize();
  const { applicationId } = useParams();
  const [open, setOpen] = useState(true);
  const [bankStatementModalOpen, setBankStatementModalOpen] = useState(false);
  const [files, setFiles] = useState<OfferFileDto[]>([]);
  const [selectedBankStatement, setSelectedBankStatement] = useState<{ fileName: string; fileUrl: string } | null>(
    null,
  );

  useEffect(() => {
    if (!open || !applicationId) return;
    getOfferFiles(applicationId).then(setFiles);
  }, [open, applicationId]);

  const handleCloseBankStatementModal = () => {
    setOpen(true);
    setBankStatementModalOpen(false);
  };

  return (
    <>
      {selectedBankStatement && (
        <DocumentViewerDrawer
          file={selectedBankStatement.fileUrl}
          open={bankStatementModalOpen}
          onClose={() => {
            handleCloseBankStatementModal();
            onClose();
          }}
          title={`View Renter's Documents`}
        />
      )}
      <Modal
        isOpen={open && openProp}
        onClose={onClose}
        styles={{ content: { maxHeight: '100%', ...(isMobile && { height: '100%' }) } }}
      >
        <div className='md:w-[715px] bg-white rounded-lg p-4 md:p-8 md:max-w-full w-screen md:h-auto relative flex flex-col gap-8'>
          <div className='flex justify-between items-center'>
            <div className='w-6 h-6' />
            <h3 className='text-xl whitespace-nowrap text-center text-neutral-800 text-[40px] font-semibold leading-10'>
              Renter's Documents
            </h3>
            <button onClick={onClose}>
              <CloseOutlined className={'text-primary text-[24px]'} />
            </button>
          </div>
          <div className={'md:p-6 p-3 bg-sky-300 rounded-xl flex-col gap-5 flex'}>
            <div className={'md:p-8 p-4 bg-sky-50 rounded-xl'}>
              <div className={'text-sky-600 text-2xl font-bold leading-normal mb-6'}>Bank Statements</div>
              <div className={'flex flex-col gap-4'}>
                {files.map((file) => (
                  <div key={file.fileName} className={'justify-between items-center gap-4 flex flex-wrap'}>
                    <div className={'flex gap-4 items-center'}>
                      <div
                        className={
                          'md:min-w-[61.19px] md:w-[61.19px] md:h-[61.19px] min-w-[40px] w-[40px] h-[40px] bg-sky-300 rounded-[116.85px] justify-center items-center flex'
                        }
                      >
                        <FileFilled className={'text-sky-50 md:text-[30.81px] text-[20px]'} />
                      </div>
                      <div
                        className={
                          'text-sky-600 text-xl font-bold leading-normal max-w-[170px] md:max-w-[250px] truncate text-ellipsis overflow-hidden'
                        }
                      >
                        {file.fileName}
                      </div>
                    </div>
                    <div className={'flex gap-4 md:w-auto w-full'}>
                      <Button
                        label={'View'}
                        startIcon={<EyeOutlined className={'text-[18px]'} />}
                        className={'md:w-[125px] h-[66px] px-8 py-3 grow'}
                        onClick={() => {
                          setOpen(false);
                          setBankStatementModalOpen(true);
                          setSelectedBankStatement(file);
                        }}
                      />
                      <button className={'text-primary text-[30px]'} onClick={() => downloadFileByUrl(file.fileUrl)}>
                        <DownloadOutlined />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { RenterDocumentsModal };
