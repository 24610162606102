import { AuthRole } from 'common/enums/enums';
import useUserProfileValidation from 'hooks/query/user/use-user-profile-validation';
import { useHasRoles } from 'hooks/use-has-roles.hook';
import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
  navigateRoute: string;
}

const IncompleteProfileNavigateGuard = ({ children, navigateRoute }: Props) => {
  const navigate = useNavigate();
  const { data } = useUserProfileValidation();
  const isLandlord = useHasRoles([AuthRole.Landlord]);
  
  const isUserVerified = () => {
    if (isLandlord && !Boolean(data?.isIdentityEnabled)) {
      return Boolean(data?.isProfileValid);
    }

    return Boolean(data?.isProfileValid && data.isIdentityValid);
  };

  useEffect(() => {
    if (!data) return;
    if (!isUserVerified()) {
      console.log(data);
      navigate(navigateRoute)
    }
  }, [data]);

  if (!data) return null;

  return (
    <>{isUserVerified() ? children : navigate(navigateRoute)}</>
  )
}

export default IncompleteProfileNavigateGuard