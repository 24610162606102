import { Divider } from "legacy-components/componets";
import { FC } from "react";
import { DEPOSIT, DOLLAR, FIRST_MONTH, LAST_MONTH, LEASE_TERMS, LEASE_TERMS_VALUE, PERMONTH, RENT } from "./EstatePriceDetails.const";
import { EstatePriceDetailsProps } from "./EstatePriceDetails.types";
import { formatPriceValue } from "helpers/string.helpers";

export const EstatePriceDetails: FC<EstatePriceDetailsProps> = ({ lastMonth, leaseLength, firstMonth, otherFee, price, deposit, axxelistFees, showBrief }) => {
    return (
        <div className='price flex flex-col'>
            <div className='label mb-1'>{RENT}</div>
            <div className='flex items-center gap-[5px]'>
                <p className='text-primaryDark text-2xl font-bold'>{`${DOLLAR}${formatPriceValue(price)}`}</p>
                <p className='text-base font-normal text-trueGray ml-[5px]'>{PERMONTH}*</p>
            </div>
            {!showBrief && (<div className='flex items-center justify-between gap-[5px] mt-2'>
                <p className='font-bold'>{`${LEASE_TERMS}`}</p>
                <p className='text-base font-normal text-trueGray ml-[5px]'>{`${LEASE_TERMS_VALUE(leaseLength)}`}</p>
            </div>)}
            <Divider style={{ margin: '15px 0' }} />
            <div className='flex items-center justify-between gap-[5px] mt-2'>
                <p className='font-bold'>{`${FIRST_MONTH}`}</p>
                <p className='text-base font-normal text-trueGray ml-[5px]'>{`${DOLLAR}${formatPriceValue(firstMonth)}`}</p>
            </div>
            {!!lastMonth && !showBrief && (<div className='flex items-center justify-between gap-[5px]'>
                <p className='font-bold'>{`${LAST_MONTH}`}</p>
                <p className='text-base font-normal text-trueGray ml-[5px]'>{`${DOLLAR}${formatPriceValue(lastMonth)}`}</p>
            </div>)}
            {!showBrief && (<div className='flex items-center justify-between gap-[5px]'>
                <p className='font-bold'>{`${DEPOSIT}`}</p>
                <p className='text-base font-normal text-trueGray ml-[5px]'>{`${DOLLAR}${formatPriceValue(deposit)}`}</p>
            </div>)}
          
            {otherFee.length > 0 &&
                otherFee.map((fee) => {
                    return (
                        <div key={fee.name} className='flex items-center justify-between gap-[5px]'>
                            <p className='font-bold'>{`${fee.name}`}</p>
                            <p className='text-base font-normal text-trueGray ml-[5px]'>{`${DOLLAR}${formatPriceValue(fee.price)}`}</p>
                        </div>
                    );
                })}
        </div>
    )
}