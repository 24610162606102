import React from 'react';
import ScreeningServicePlanCardProps from '../types/ScreeningServicePlanCardProps';
import './styles.css';


const ScreeningServicePlanCard: React.FC<ScreeningServicePlanCardProps> = (props) => {
    const {adComponent, actionComponent, data} = props;

    const services = JSON.parse(data?.includes ?? '[]') as string[];

    return (
        <div className="flex flex-col items-center gap-y-3 px-2 pt-6 w-[280px] h-[495px] 
                        relative bg-white rounded border-2 border-zinc-100 hover:border-primary"
        >
            {adComponent}           
            
            <div className="px-4 md:h-[70px]">
                <span className="font-bold text-[18px] text-zinc-600">{data?.title}</span>
            </div> 
                
            <div className="px-4 font-bold">
                <span className="text-[32px]">&#x24;{data?.price}<span className="text-[18px] text-zinc-600"> / </span></span>
                <span className="text-[14px] text-zinc-500">applicant</span>
            </div> 

            { actionComponent && (<div> {actionComponent} </div>) }

            <div className="my-4">
                <div className="w-[220px] h-px bg-zinc-200">&nbsp;</div>
            </div>

            <div>
                {
                    (services && services.length > 0) && (
                        <ul className="w-[200px] font-bold px-4 text-[11px] text-zinc-500 list-style-none checkmarked">
                            { 
                                services.map((element, idx) => <li key={idx} className="px-1">{element}</li>)
                            }
                        </ul>
                    )
                }
            </div>

            <div className="absolute left-[20px] bottom-[50px] text-[13px] text-zinc-400"><small>Credit data provider</small></div>
            <img src="/transunion-logo192.png" alt="Powered by TransUnion" className="w-[92px] h-[56px] absolute right-[13px] bottom-[5px]" />
        </div>
    )
}

export default ScreeningServicePlanCard;
