import { CellProps } from 'react-table';
import { DropdownMenu, Icon } from 'legacy-components/componets';
import { OffersActionsCell, OffersTableRow } from '../common/types/OfferTable.types';
import Dropdown from 'rc-dropdown';
import { PROPERTY } from '../common/constants/OfferTableActions.constants';
import { getMenuItems } from '../common/utils/OfferPageActions.utils';

export const OfferActionCell = ({ value }: CellProps<OffersTableRow, OffersActionsCell>) => {
  const { onResend, onCancel, onViewEstateProfile, onViewPreApplicationPayment, onViewFullApplicationPayment, id, status, onViewRejectionReason, rejectionReason } = value;
  const menuItems = getMenuItems(
    onResend,
    onViewRejectionReason,
    onCancel,
    onViewEstateProfile,
    onViewPreApplicationPayment,
    onViewFullApplicationPayment,
    status,
    rejectionReason
  );

  return (
    <div>
      <Dropdown
        trigger={['click']}
        overlay={<DropdownMenu key={id} menuItems={menuItems} headerTitle={PROPERTY} hasDivider />}
      >
        <Icon name={'dots-menu'} onClick={() => { }} />
      </Dropdown>
    </div>
  );
};
