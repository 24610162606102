import { ScreeningServicePlan } from "common/types/services/api/tenant-screening";
import { ApplicantScreeningReportDto } from "legacy-pages/landlord/profile/background-check/types";
import { axiosInstance } from "./axiosInstance";

export const getApplicantScreeningReport = (userId: string): Promise<ApplicantScreeningReportDto[]> => {
  return axiosInstance.get(`userAccess/backgroundcheck/${userId}`);
};

export const getOrderPlans = (location?: string): Promise<ScreeningServicePlan[]> => {
  return axiosInstance.get(`userAccess/backgroundcheck/orders?location=${location}`);
};
