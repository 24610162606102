import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Icon, Input, Label, Loader, PhoneInput } from 'legacy-components/componets';
import { InputProps } from 'common/types/types';
import { signUpValidationSchema } from 'validations';
import { CustomDatePicker } from 'legacy-components/fields/fields';
import { validate } from 'uuid';
import { ValidationError } from 'yup';
import { groupBy, mapValues } from 'lodash-es';
import PasswordInput from 'legacy-components/ui/password-input/password-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { ENV } from 'common/enums/enums';
import { isNotEmptyString } from 'common/utils/check-empty-string';
import { signUpPartnerFormToDtoSignUpRequest } from 'common/mappers/auth';
import { useSignUp } from 'hooks/query';
import { isAxiosError } from 'axios';
import { AgreeAndContinue } from 'legacy-pages/pages';
import { ISignUpRenterValues } from '../sign-up-renter-form/sign-up-renter-form';
import { getParamFromLocation } from 'common/utils/get-param-from-location';
import { useGetMasterAgreementApplicant } from 'hooks/query/use-master-agreement';

export interface ISignUpPartnerValues extends ISignUpRenterValues {
  partnerRefId: string;
}

const SignUpPartnerForm = () => {
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { values, errors, touched, setFieldValue, handleSubmit, setValues } = useFormik<ISignUpPartnerValues>({
    initialValues: {
      type: { value: 'apartment', label: 'An Apartment' },
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
      comment: '',
      dateOfBirth: '',
      captchaToken: '',
      partnerRefId: ''
    },
    validate: async (values) => {
      try {
        await signUpValidationSchema.validate(values, { abortEarly: false });
        return {};
      } catch (err) {
        if (!ValidationError.isError(err)) return {};
        return mapValues(groupBy(err.inner, 'path'), (e) => e.flatMap((e) => e.errors));
      }
    },
    onSubmit: (values: ISignUpPartnerValues) => handleRenterSignUp(values),
  });

  const { mutateAsync: signUp, isPending } = useSignUp();
  const partnerRef = getParamFromLocation('partner_ref');
  const { data: applicantInfo, isLoading: isApplicantLoading } = useGetMasterAgreementApplicant(partnerRef || '', {
    enabled: !!partnerRef
  });

  useEffect(() => {
    if (!partnerRef || !validate(partnerRef)) {
        navigate('/not-found');
    } else {
      setValues({ ...values, partnerRefId: partnerRef })
    }
  }, [partnerRef, navigate]);

  useEffect(() => {
    if (!applicantInfo) return;
    setValues({...values, 
      firstName: applicantInfo.firstName, 
      lastName: applicantInfo.lastName, 
      dateOfBirth: applicantInfo.dateOfBirth, 
      email: applicantInfo.email });
  }, [applicantInfo, navigate]);

  const handleRenterSignUp = async (values: ISignUpPartnerValues) => {
    try {
      await signUp(signUpPartnerFormToDtoSignUpRequest(values));
    } catch (e) {
      if (isAxiosError(e)) {
        setErrorMessage(e.response?.data.detail || e.message);
      }
      // if we receive error we need to reset captcha
      setFieldValue('captchaToken', '');
      reCaptchaRef?.current?.reset();
    }
  };

  const getCommonInputProps = (
    name: keyof Omit<ISignUpPartnerValues, 'dateOfBirth'>,
    label: string,
    placeholder: string,
  ): InputProps => {
    return {
      name,
      label,
      placeholder,
      theme: 'default',
      value: values[name],
      onChange: ({ target: { value } }) => setFieldValue(name, value),
      invalid: Boolean(errors[name] && touched[name]),
    };
  };

  const showError = (fieldName: keyof ISignUpPartnerValues): boolean => {
    return Boolean(errors[fieldName] && touched[fieldName]);
  };

  const handleRecaptcha = (value: any) => {
    setFieldValue('captchaToken', value);
  };

  if (isApplicantLoading) {
    return <Loader />
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='md:px-4 px-5 py-6 max-w-[620px]'>
        <div className='w-full text-black text-xl font-semibold text-left mb-3'>Personal Details</div>
        <div className='w-full text-trueGray text-base font-normal text-left mb-3'>
          Please start filling out your application by filling in your personal information below.
        </div>
        <div className={`sign-in-link text-sm ${errorMessage || showError('captchaToken') ? '' : 'mb-[68px]'}`}>
          <span className='font-bold text-warmGray'>Already have an account?</span>
          <span>&nbsp;</span>
          <NavLink to={'/auth/sign-in'} className='text-primary text-sm'>
            Log in
          </NavLink>
        </div>
        {errorMessage && (
          <div
            className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
              errorMessage ? 'mt-4 mb-4' : ''
            }`}
          >
            <p>{errorMessage}</p>
            <div className='cursor-pointer' onClick={() => setErrorMessage('')}>
              <Icon name={'times-circle'} />
            </div>
          </div>
        )}
        {showError('captchaToken') && (
          <div
            className={`text-sm text-error p-2 bg-red-100 rounded-lg flex justify-between ${
              showError('captchaToken') ? 'mt-4 mb-4' : ''
            }`}
          >
            <p>{errors.captchaToken}</p>
          </div>
        )}
        <form className={`flex flex-col`} onSubmit={handleSubmit}>
          <div
            className={`flex md:flex-row flex-col gap-4 
                    ${showError('email') || showError('password') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('email', 'Email', 'hi@example.com')} />
              {showError('email') && <p className='text-sm text-error py-1'>{errors.email}</p>}
            </div>
            <div className='md:w-1/2 w-full'>
              <PasswordInput
                {...getCommonInputProps('password', 'Password', '')}
                showHintPopup
                errors={errors.password}
                showError={showError('password')}
                autoCompleteType={'one-time-code'}
              />
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col gap-4 
                        ${showError('firstName') || showError('lastName') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('firstName', 'First Name', 'First Name')} />
              {showError('firstName') && <p className='text-sm text-error py-1'>{errors.firstName}</p>}
            </div>
            <div className='md:w-1/2 w-full'>
              <Input {...getCommonInputProps('lastName', 'Last Name', 'Last Name')} />
              {showError('lastName') && <p className='text-sm text-error py-1'>{errors.lastName}</p>}
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col gap-4 
                            ${showError('dateOfBirth') || showError('phone') ? '' : 'mb-6'}`}
          >
            <div className='md:w-1/2 w-full'>
              <Label label={'Date of birth'}>
                <CustomDatePicker
                  name='dateOfBirth'
                  value={values.dateOfBirth}
                  invalid={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
                  onChange={(date) => {
                    setFieldValue('dateOfBirth', date?.toDateString());
                  }}
                />
              </Label>
              {showError('dateOfBirth') && <p className='text-sm text-error py-1'>{errors.dateOfBirth}</p>}
            </div>
            <div className='md:w-1/2 w-full'>
              <Label label={'Phone Number'}>
                <div className='flex gap-2'>
                  <div className='w-full' aria-label='Phone Number'>
                    <PhoneInput
                      isValid={!showError('phone')}
                      value={values.phone}
                      onChange={(value, data, event, formattedValue) => {
                        setFieldValue('phone', value);
                      }}
                    />
                  </div>
                </div>
              </Label>
              {showError('phone') && <p className='text-sm text-error py-1'>{errors.phone}</p>}
            </div>
          </div>
          <div className='mb-6'>
            <AgreeAndContinue />
          </div>
          <div className='w-full flex flex-wrap flex-col sm:flex-row gap-4 justify-between'>
            {isNotEmptyString(ENV.REACT_APP_RECAPTCHA) && (
              <div className='flex justify-start'>
                <ReCAPTCHA ref={reCaptchaRef} sitekey={ENV.REACT_APP_RECAPTCHA} onChange={handleRecaptcha} hl='en' />
              </div>
            )}
            <div className='flex justify-end items-end flex-1'>
              <Button
                label={'Agree and continue'}
                type={'submit'}
                theme={'primary'}
                onClick={() => {}}
                isLoading={isPending}
                className='mb-[2px]'
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { SignUpPartnerForm };
