import { useOfferDetails } from 'hooks/query';
import { Loader } from 'legacy-components/componets';
import { useParams } from 'react-router-dom';
import TenantScreeningSection from './components/TenantScreeningSection';
import ApplicationSummaryContainer from '../application-summary/components/ApplicationSummaryContainer';

const BackgroundCheck = () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { data: offerDetails, isLoading: isOfferDetailsLoading } = useOfferDetails(applicationId);

  if (isOfferDetailsLoading) {
    return <Loader />;
  }
  
  return (
    <ApplicationSummaryContainer applicationId={applicationId} offerDetails={offerDetails}>
      <TenantScreeningSection renterId={offerDetails?.renterId || ''} />
    </ApplicationSummaryContainer>
  );
}

export { BackgroundCheck as BackgroundCheckInfo };