import { Checkbox, Typography, Box } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { colors } from 'colors';

interface Props {
  label: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  showIcon?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LeaseDocumentCheckbox = ({ label, name, onChange, checked = false, disabled = false, showIcon = true }: Props) => {
  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      padding: '8px 16px',
      borderRadius: '8px',
      backgroundColor: disabled ? '#F0F0F0' : '#F1FBFF',
      color: disabled ? 'text.disabled' : colors.primaryDark,
      marginBottom: '8px'
    }}>
      {showIcon && <InsertDriveFileIcon 
        sx={{ 
          marginRight: '8px',
          width: '30px',
          height: '30px',
          backgroundColor: colors.primary,
          borderRadius: '50%',
          padding: '5px',
          color: 'white' }} />}
      <Typography sx={{ flex: 1, color: disabled ? 'text.disabled' : colors.primaryDark }}>
        {label}
      </Typography>
      <Checkbox
        name={name}
        checked={checked}
        disabled={disabled}
        color="default"
        onChange={onChange}
      />
    </Box>
  );
};

export default LeaseDocumentCheckbox;