import { Fragment, useEffect, useState } from 'react';
import { Button, GoBackLink, Loader } from 'legacy-components/componets';
import { storage } from 'services/services';
import { NotificationType, StorageKey } from 'common/enums/enums';
import { useNavigate } from 'react-router-dom';
import { useSendPhoneConfirmation, useRequestPhoneConfirmation } from 'hooks/query';
import { InputCode } from 'components/atoms/Input/InputCode';
import { NotificationModal } from 'legacy-components/modals/notification-modal-new-version/NotificationModal';
import { isAxiosError } from 'axios';
import { Notification } from 'services/notification.service';
import { useGetMasterAgreementApplicant } from 'hooks/query/use-master-agreement';
import { getParamFromLocation } from 'common/utils/get-param-from-location';

const SignUpPhoneConfirmationForm = () => {
  const navigate = useNavigate();
  const partnerRef = getParamFromLocation('partner_ref');
  const { data: applicantInfo, isLoading: isApplicantLoading } = useGetMasterAgreementApplicant(partnerRef || '', {
    enabled: !!partnerRef
  });

  const [notificationModal, setNotificationModal] = useState<{
    open: boolean;
    title: string;
    type: NotificationType;
    descr: string;
    onClose?: () => void;
  }>({
    open: false,
    title: '',
    type: NotificationType.INFO,
    descr: '',
  });

  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const [confirmationCodeError, setConfirmationCodeError] = useState<boolean>(false);
  const [resendConfirmationCodeError, setResendConfirmationCodeError] = useState<boolean>(false);

  const registrationId = storage.getItem(StorageKey.REGISTRATION_ID) ?? '';

  const isConfirmationCodeSetted = confirmationCode.length === 6;
  const isRegistrationId = Boolean(registrationId);
  const isConfirmBtnDisabled = !isConfirmationCodeSetted || !isRegistrationId;

  const handleCloseModal = () => {
    setNotificationModal({
      open: false,
      title: '',
      type: NotificationType.INFO,
      descr: '',
    });
  };

  const handleOpenErrorModalWithAttempt = () => {
    setNotificationModal({
      open: true,
      title: 'Verification failed',
      type: NotificationType.ERROR,
      descr: 'Please use correct code from your SMS',
      onClose: handleCloseModal,
    });
  };

  const handleOpenErrorModalWithRedirect = () => {
    setNotificationModal({
      open: true,
      title: 'Ooops! Something went wrong',
      type: NotificationType.ERROR,
      descr: "It seems there's an issue. You will be redirected to the previous page",
      onClose: () => {
        navigate('/auth/sign-up');
        storage.removeItem(StorageKey.REGISTRATION_ID);
      },
    });
  };

  const handleOpenSuccessModal = () => {
    partnerRef 
      ? setNotificationModal({
        open: true,
        title: 'Account confirmed',
        type: NotificationType.SUCCESS,
        descr: 'You will be redirected to the page with estate info',
        onClose: () => {
          applicantInfo 
            ? navigate(`/estates/${applicantInfo.estateId}?unitId=${applicantInfo.unitId}`)
            : navigate('/estates/grid');
          storage.removeItem(StorageKey.REGISTRATION_ID);
        },
      }) 
      : setNotificationModal({
        open: true,
        title: 'Account confirmed',
        type: NotificationType.SUCCESS,
        descr: 'You will be redirected to the sign-in page',
        onClose: () => {
          navigate('/auth/sign-in');
          storage.removeItem(StorageKey.REGISTRATION_ID);
        },
      });
  };


  const { mutateAsync: sendPhoneConfirmation, isPending } = useSendPhoneConfirmation({
    onSuccess: handleOpenSuccessModal,
    onError: (error) => {
      if (isAxiosError(error)) {
        const isCodeError = error.response?.data.detail === 'Incorrect code';
        if (isCodeError) {
          setConfirmationCodeError(true);
          handleOpenErrorModalWithAttempt();
        } else {
          handleOpenErrorModalWithRedirect();
        }
      } else {
        console.error('UNKNOWN ERROR');
        handleOpenErrorModalWithRedirect();
      }
    },
  });

  const { mutateAsync: requestPhoneConfirmation } = useRequestPhoneConfirmation({
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The confirmation code has been sent to your phone');
    },
    onError: (error) => {
      setResendConfirmationCodeError(true);
      if (isAxiosError(error)) {
        const isMaxAttemptsError = error.response?.data.detail === 'Max send attempts reached';
        setNotificationModal({
          open: true,
          title: 'Failed to resend verification code',
          type: NotificationType.ERROR,
          descr: isMaxAttemptsError
            ? 'Sorry, you have exceeded your code resend limit, please try again later'
            : 'Sorry, an unknown error occurred, please try again later',
          onClose: handleCloseModal,
        });
      } else {
        console.error('UNKNOWN ERROR');
        setNotificationModal({
          open: true,
          title: 'Failed to resend verification code',
          type: NotificationType.ERROR,
          descr: 'Sorry, an unknown error occurred, please try again later',
          onClose: handleCloseModal,
        });
      }
    },
  });

  const handleInputConfirmationCode = (confirmationCode: string) => {
    setConfirmationCode(confirmationCode);
  };

  const handleConfirmPhoneNumber = () => {
    sendPhoneConfirmation({
      registrationId,
      code: confirmationCode,
    });
  };

  const handleInputCodeChange = () => {
    if (confirmationCodeError) {
      setConfirmationCodeError(false);
    }
  };

  useEffect(() => {
    if (!isRegistrationId) {
      handleOpenErrorModalWithRedirect();
    }
  }, [isRegistrationId, registrationId]);

  if (isApplicantLoading) {
    return <Loader />
  }

  return (
    <Fragment>
      <NotificationModal
        open={notificationModal.open}
        onClose={notificationModal?.onClose}
        title={notificationModal.title}
        notificationType={notificationModal.type}
        description={notificationModal.descr}
      />
      <div className='flex flex-col justify-center items-center md:min-h-[calc(100vh_-_200px)]'>
        <div className='wrapper md:px-12 px-5 md:py-[28px] py-6 max-w-[620px]'>
          <div className='mb-9'>
            <GoBackLink
              title={'Back to Sign up'}
              onClick={() => {
                storage.removeItem(StorageKey.REGISTRATION_ID);
                navigate('/auth/sign-up');
              }}
              titleStyles={{
                fontWeight: 700,
                fontSize: '16px',
              }}
            />
          </div>
          <div className='w-full text-black text-xl font-semibold text-left mb-3'>Confirm your account</div>
          <div className='w-full text-trueGray text-base font-normal text-left mb-3'>
            We have sent you a one-time code on your phone number. Please enter it below to finish setting up your
            account and arranging your self tour.
          </div>
          <div className={`sign-in-link text-xs`}>
            <span className='font-bold text-warmGray'>Didn’t receive a code?</span>
            <span>&nbsp;</span>
            <button
              onClick={() => requestPhoneConfirmation(registrationId)}
              disabled={!isRegistrationId || resendConfirmationCodeError}
              className='text-primary p-3 pl-0 disabled:opacity-75 disabled:text-warmGray'
            >
              Send again
            </button>
          </div>
          <div className='mt-9'>
            <InputCode
              isError={confirmationCodeError}
              isLoading={isPending}
              callback={handleInputConfirmationCode}
              onChange={handleInputCodeChange}
            />
          </div>
          {confirmationCodeError && (
            <div className={`text-sm font-semibold text-error p-2 bg-red-100 rounded-lg mt-3`}>
              <p>{'Verification failed. Please use correct code from your SMS'}</p>
            </div>
          )}
          <div className='flex items-center justify-end gap-6 mt-9'>
            <Button
              stylesConfig={{ width: 120 }}
              disabled={isConfirmBtnDisabled}
              isLoading={isPending}
              label={'Confirm'}
              onClick={handleConfirmPhoneNumber}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export { SignUpPhoneConfirmationForm };
