import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { QueryKey } from "common/enums/query-key";
import { ApplicantScreeningReportDto } from "legacy-pages/landlord/profile/background-check/types";
import { getApplicantScreeningReport } from "services/api/background-check-api.service";

export default function useApplicantScreeningReport(
  userId: string,
  options?: Omit<UndefinedInitialDataOptions<ApplicantScreeningReportDto[] | undefined, Error, ApplicantScreeningReportDto[], (string | undefined)[]>, 'queryFn' | 'queryKey'>,
) {

  return useQuery({
    queryKey: [QueryKey.ApplicantScreeningReport, userId],
    queryFn: () => { 
      if (!userId) return;
      return getApplicantScreeningReport(userId) 
    },
    enabled: !!userId,
    ...options,
  });
}