import { AppRoute } from 'common/enums/route/route';
import { UserDetailsDto } from 'common/types/services/api/user/user-details-dto.type';
import { getDayjs } from 'helpers/date';
import { useNavigate } from 'react-router-dom';

export type UserInfoProps = {
    userProfile?: UserDetailsDto;
};

const UserInfo = ({ userProfile }: UserInfoProps) => {
    const navigate = useNavigate();

    if (!userProfile) return null;

    const handleOpenModal = () => {
        navigate(AppRoute.PROFILE_INFO)
    };

    return (
        <div className='flex flex-col gap-1 bg-[#FAFAFA] p-[24px] rounded-2xl'>
            <div className='font-bold'>
                {userProfile.firstName} {userProfile.lastName}
            </div>
            <div>
                <span className='font-bold'>DOB: </span> {getDayjs(userProfile.dateOfBirth).format('MM/DD/YYYY')}
            </div>
            <div>
                <span className='font-bold'>Tele: </span> {userProfile.phoneNumber}
            </div>
            <div className="truncate">
                <span className='font-bold'>Email: </span> 
                <span title={userProfile.email}>{userProfile.email}jhgjhgjhgjgjhghjgjhghjgjhgjhg</span>
            </div>
            <div> 
                <span className='font-bold'>SSN#: </span> {getMaskedSsn(userProfile.socialSecurityNumber)}  
            </div>
            <div className='text-sm mt-4'>
                <span className='mr-1'>
                    Something wrong?
                </span>
                <span className='text-sm text-primary text-center cursor-pointer' onClick={handleOpenModal}>
                    Edit profile
                </span>
            </div>
        </div>
    );
};

function getMaskedSsn(ssn: string) {
    return `***-**-${ssn.slice(5)}`
}

export { UserInfo };
