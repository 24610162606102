import { Modal } from 'components/organisms/Modal';
import { FormikProvider, useFormik } from 'formik';
import { useWindowSize } from 'hooks';
import { useGetLeaseDocuments } from 'hooks/query';
import { Button, Loader, UploadFiles } from 'legacy-components/componets';
import { uploadFileConfig } from 'legacy-components/fields/upload-files/config';
import { useEffect, useState } from 'react';
import { SelectedFile, SendLeaseDocumentFormValues } from './send-lease.types';
import { maxFileSize, maxFileSizeErrorMessage } from './send-lease-validation';
import { TextField } from '@mui/material';
import LeaseDocumentCheckbox from './LeaseDocumentCheckbox';
import { useCreateLeaseDocument, useSendLeaseDocument } from 'hooks/query/use-lanldlord-lease-documents';
import { notification } from 'services/services';
import { CreateLeaseDocument } from 'common/types/services/api/landlord-lease-documents/landlord-lease-documents.types';
import { removeFileExtension } from 'common/utils/remove-file-extension';
import { stringWithOutExtraSpaces } from 'common/utils/string-without-extra-spaces';
import { v4 } from 'uuid';

interface Props {
  onClose: () => void;
  withGoBackButton?: boolean;
  isOpen: boolean;
  applicationId: string;
  renterId: string;
}

const SendLeaseModal = ({ onClose, isOpen, applicationId, renterId }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: leaseDocuments, isLoading: isLeaseDocumentsLoading } = useGetLeaseDocuments({
    page: 1,
    perPage: 150, // TODO: in case if landlords have huge amount of documents think about adding pagination
  });

  const { mutateAsync: addNewLeaseDocument } = useCreateLeaseDocument({
    onError: (e) => {
      notification.error(e?.response?.data.message || 'Lease cannot be uploaded, try again later.');
    },
  });
  const { mutateAsync: sendLease, isPending: isSendLeasePending } = useSendLeaseDocument();
  
  const { isMobile } = useWindowSize();
  
  const formik = useFormik<SendLeaseDocumentFormValues>({
    initialValues: { sendMasterAgreement: true, files: [], addedFiles: [] },
    onSubmit: async (values) => await handleSubmit(values),
  });
  const { values, errors, setFieldValue, submitForm, resetForm , setValues, isSubmitting, setFieldError } = formik
    
  useEffect(() => {
    if (!leaseDocuments) return;
    setValues({
      ...values,
      files: leaseDocuments.items?.map((x) => { return { id: x.id, name: x.fileName, selected: false } }) ?? []
    });

  }, [leaseDocuments]);

  const handleSubmit = async (values: SendLeaseDocumentFormValues) => {
    let newFileIds: string[] = [];

    if (values.addedFiles?.length) {
      newFileIds = await addNewLeaseDocument(values.addedFiles);
    }

    const fileIds = values.files.filter(x => x.selected && !x.newFile).map(x => x.id);
    
    if (newFileIds.length) {
      fileIds.push(...newFileIds);
    }

    try {
      await sendLease({
        renterId,
        applicationId,
        fileIds, 
        sendMasterAgreement: values.sendMasterAgreement
      });
    } catch (e) {
      notification.error('Lease cannot be sent, try again later.');
    }
    

    resetForm();
    onClose();
  };

  const handleCheckboxChange = (fileId: string, isChecked: boolean) => {
    const fileIndex = values.files.findIndex(file => file.id === fileId);

    if (fileIndex !== -1) {
      setFieldValue(`files.${fileIndex}.selected`, isChecked);
    }
  };

  return (
    <Modal
      title={'Send the lease'}
      isOpen={isOpen}
      isFullScreen={isMobile}
      contentClassName={'w-[550px]'}
      onOpenChange={(open) => {
        if (open) return;
        onClose();
      }}
    >
      {isLeaseDocumentsLoading ? (<Loader />) : (
        <FormikProvider value={formik}>
          <div className='flex flex-col gap-6 text-base font-medium'>
            <div id='lease-title' className='flex justify-center'>
              <p>Please ensure the correct documents are selected</p>
            </div>
            <div id='lease-description'>The following documents are attached to the property. You can add additional documents if required. The tenant will be required to sign the Axxelist Master Agreement, which is a comprehensive contract that includes and references all these documents under a single agreement</div>
            <div id='lease-search-box' className='flex flex-col'>
              <p className='pb-3 text-neutral-800 font-bold'>Add from library</p>
              <TextField 
                label="Search" 
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value || '')} />
            </div>
            <div className='h-64 overflow-y-auto'>
              {values.files && values.files
                .filter(file => searchTerm ? file.name.toLocaleLowerCase().includes(searchTerm.toLowerCase()) : true)
                .map(file => (
                <LeaseDocumentCheckbox key={`files.${file.id}.selected`}
                  name={`files.${file.id}.selected`}
                  checked={file.selected}
                  label={file.name}
                  onChange={(e) => {
                    handleCheckboxChange(file.id, e.target.checked);
                  }} />
              ))}
              <LeaseDocumentCheckbox name='sendMasterAgreement' label={'Axxelist Master Agreement'} disabled={true} checked={true} showIcon={false} />
            </div>
            <UploadFiles
                  multiple
                  name={'addedFiles'}
                  title={uploadFileConfig.pdf.title}
                  accept={uploadFileConfig.pdf.accept}
                  onDrop={(files) => {
                    if (files?.length && files.some(x => x.size > maxFileSize)) {
                      setFieldError('addedFiles', maxFileSizeErrorMessage);
                      return;
                    } else {
                      setFieldError('addedFiles', '');
                    }

                    const addedFiles = files.map(x => { 
                      return { file: x, title: removeFileExtension(stringWithOutExtraSpaces(x.name)) } as CreateLeaseDocument;
                    });

                    setFieldValue('addedFiles', [...values.addedFiles, ...addedFiles]);
                    const newFiles = files.map(x => { return { id: v4(), selected: true, newFile: true, name: x.name } as SelectedFile})
                    setFieldValue('files', [...values.files, ...newFiles]);
                  }}
                  invalid={Boolean(errors?.addedFiles)}
                />
                {errors.addedFiles && (
                  <p className='text-red-500 text-[16px] font-normal leading-none'>{errors.addedFiles as string}</p>
                )}
            <Button label={'Submit'} theme={'primary'} onClick={submitForm} isLoading={isSendLeasePending || isSubmitting} />
          </div>
        </FormikProvider>)}
    </Modal>
  );
}

export default SendLeaseModal