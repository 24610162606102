import { GoBackLink } from 'legacy-components/componets';
import { getDate } from 'helpers/date';
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum';
import { EstateTypeDto } from 'common/enums/enums';
import { OffersItemDto } from 'common/types/services/api/offers/offer-item-dto.type';
import { useNavigate } from 'react-router-dom';
import ApplicationSummaryStatusActions from './ApplicationSummaryStatusActions';
import { ApplicationPropertyCard } from './ApplicationPropertyCard';
import OfferRejectedReason from './OfferRejectedReason';
import { ApplicationPropertyDetails } from './ApplicationPropertyDetails';
import { ApplicationPersonalInfo } from './ApplicationPersonalInfo';
import { ReactNode } from 'react';
import { ApplicationPropertyRenterDocumentsDetails } from 'components/templates/Offers/ApplicationPropertyRenterDocumentsDetails';

interface Props {
  applicationId: string;
  offerDetails?: OffersItemDto;
  children?: ReactNode;
}

const ApplicationSummaryContainer = ({ applicationId, offerDetails, children }: Props) => {
  const navigate = useNavigate();
  
  return (
    <div className='w-full'>
      <div className='flex gap-6 flex-wrap justify-between items-center px-6 lg:px-3 mb-5'>
        <GoBackLink title={'Back'} onClick={() => navigate(-1)} />
        <div className='button-group flex-wrap flex gap-6 items-center'>
          {offerDetails && <ApplicationSummaryStatusActions offerDetails={offerDetails} applicationId={applicationId} />}
        </div>
      </div>

      <div className='wrapper flex flex-col gap-6 rounded-lg px-6 py-8 p-6 border-[1.5px] border-gray'>
        <h2 className='text-2xl font-bold'>Application Summary</h2>
        {offerDetails?.status === OfferStatusDto.Reject && <OfferRejectedReason offerDetails={offerDetails} />}
        <div className='flex w-full justify-center items-center'>
          <ApplicationPropertyCard
            id={offerDetails?.estateId || ''}
            title={offerDetails?.city || ''}
            address={offerDetails?.location || ''}
            image={offerDetails?.thumbnailUrl || ''}
            isMultiUnit={(offerDetails?.type as EstateTypeDto) === EstateTypeDto.MultiUnit}
            unitNumber={offerDetails?.unitNumber || ''}
            info={{
              beds: offerDetails?.beds || 0,
              bathrooms: offerDetails?.bathrooms || 0,
              area: offerDetails?.propertyArea || 0,
              hideUnits: true,
            }}
            appliedAt={offerDetails?.appliedAt || ''}
          />
        </div>

        <ApplicationPropertyDetails
          detailsMessage={offerDetails?.description || ''}
          incomeToRent={
            Boolean(offerDetails?.price)
              ? Number(((offerDetails?.proposedRent || 0) / (offerDetails?.price || 0)).toFixed(1))
              : Number((offerDetails?.proposedRent || 0).toFixed(1))
          }
          moveInDate={getDate(offerDetails?.moveInDate)}
          adultOccupants={offerDetails?.adultOccupants || 0}
          childrenOccupants={offerDetails?.childrenOccupants || 0}
          totalIncome={offerDetails?.price || 0}
          proposedRent={offerDetails?.proposedRent || 0}
        />
        <ApplicationPropertyRenterDocumentsDetails title='Bank Statements'/>

        {children}

        <ApplicationPersonalInfo
          firstName={offerDetails?.renterName || ''}
          lastName={''}
          isVerified
          state={''}
          city={''}
          years={offerDetails?.renterAge ?? 0}
          dateOfBirth={getDate(offerDetails?.renterDateOfBirth)}
          img={offerDetails?.renterThumbnailUrl ?? null}
          ssn={offerDetails?.ssn || ''}
        />
      </div>
    </div>
  );
}

export default ApplicationSummaryContainer