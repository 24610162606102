import { CreditCheckReportDto } from '../../types';
import CreditScoreReportBar from './CreditScoreReportBar/CreditScoreReportBar';

interface Props {
  report?: CreditCheckReportDto;
  isBlurred?: boolean;
}

const CreditCheckReport = ({ report, isBlurred = false }: Props) => {
  return (
    <div style={{maxHeight: 'max-content'}} className='flex-col basis-1/2 border-[1.5px] border-gray rounded-lg p-3'>
      <h4 className='text-xl font-bold py-2 leading-[2.25]'>Credit Report</h4>
      <CreditScoreReportBar creditScore={report?.creditScore} isBlurred={isBlurred} />
    </div>
  )
}

export default CreditCheckReport