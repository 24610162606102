import { OffersAction } from '../types/OfferTable.types';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import {
  CANCEL_OFFER,
  RESEND_OFFER,
  PAY_ESTATE,
  VIEW_ESTATE,
  RESEND_OFFER_COMMENT,
} from '../constants/OfferTableActions.constants';
import { OfferStatusDto } from 'common/enums/enums';
import { isNotEmptyString } from 'common/utils/check-empty-string';

export const getMenuItems = (
  onResend: OffersAction,
  onViewRejectionReason: OffersAction,
  onCancel: OffersAction,
  onViewEstateProfile: OffersAction,
  onViewPreApplicationPayment: OffersAction,
  onViewFullApplicationPayment: OffersAction,
  status: string,
  rejectReason: string,
): DropdownMenuItem[] => {
  const menuItems: DropdownMenuItem[] = [
    {
      id: 0,
      title: PAY_ESTATE,
      show: showPayPreApplicationButton(),
      onClick: () => onViewPreApplicationPayment(),
      icon: 'credit-card',
    },
    {
      id: 1,
      show: status === OfferStatusDto.LeaseSigned,
      title: PAY_ESTATE,
      onClick: () => onViewFullApplicationPayment(),
      icon: 'credit-card',
    },
    {
      id: 2,
      title: VIEW_ESTATE,
      show: true,
      onClick: () => onViewEstateProfile(),
      icon: 'faEye',
    },
    {
      id: 3,
      show: status === OfferStatusDto.Reject,
      title: RESEND_OFFER,
      onClick: () => onResend(),
      icon: 'repeat',
    },
    {
      id: 4,
      show: status === OfferStatusDto.Reject && isNotEmptyString(rejectReason),
      title: RESEND_OFFER_COMMENT,
      onClick: () => onViewRejectionReason(),
      icon: 'reject-offer-icon',
    },
    {
      id: 5,
      title: CANCEL_OFFER,
      show: status === OfferStatusDto.Reject || status === OfferStatusDto.PreApplicationPaid || status === OfferStatusDto.Accept,
      onClick: () => onCancel(),
      icon: 'shape',
      additionalStyles: 'text-red-500',
    },
  ].filter((el) => el.show) as DropdownMenuItem[];

  function showPayPreApplicationButton() {
    return status === OfferStatusDto.PendingPayment || status === OfferStatusDto.PaymentFailed;
  }

  return menuItems;
};
