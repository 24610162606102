import { FC, SVGProps } from 'react';
import { IconSVGName } from 'common/types/components/ui/ui';
import { ReactComponent as SingleFamilyHouseIcon } from 'assets/icons/categories/singleFamilyHouse.svg';
import { ReactComponent as TinyHomeIcon } from 'assets/icons/categories/tinyHome.svg';
import { ReactComponent as MultiFamilyHouseIcon } from 'assets/icons/categories/multiFamilyHouse.svg';
import { ReactComponent as TownHouseIcon } from 'assets/icons/categories/townhouse.svg';
import { ReactComponent as CondoIcon } from 'assets/icons/categories/condo.svg';
import { ReactComponent as ApartmentIcon } from 'assets/icons/categories/apartment.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/credit-cards/visa.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { ReactComponent as FiltersIcon } from 'assets/icons/filters.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CircleCheckedIcon } from 'assets/icons/circle-checked.svg';
import { ReactComponent as CircleCheckedRegularIcon } from 'assets/icons/circle-checked-regular.svg';
import { ReactComponent as CircleUncheckedIcon } from 'assets/icons/circle-unchecked.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as BedIcon } from 'assets/icons/bed.svg';
import { ReactComponent as BathIcon } from 'assets/icons/bath.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/ruler-triangle.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/tree.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as DollarSolidCircle } from 'assets/icons/dollar-solid-circle.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/social/email.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/social/apple.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/social/facebook.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/social/google.svg';
import { ReactComponent as DotsMenuIcon } from 'assets/icons/dots-menu.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as TimesCircleIcon } from 'assets/icons/times-circle.svg';
import { ReactComponent as RepeatIcon } from 'assets/icons/repeat.svg';
import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import { ReactComponent as WarningRoundIcon } from 'assets/icons/warning-round.svg';
import { ReactComponent as CloseCircleIcon } from 'assets/icons/close-circle.svg';
import { ReactComponent as ShowIcon } from 'assets/icons/show.svg';
import { ReactComponent as ShowDocumentIcon } from 'assets/icons/show-document.svg';
import { ReactComponent as CancelOutLinedCustomIcon } from 'assets/icons/cancel-outlined.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hide.svg';
import { ReactComponent as HouseIcon } from 'assets/icons/house.svg';
import { ReactComponent as ShapeIcon } from 'assets/icons/shape.svg';
import { ReactComponent as HandshakeIcon } from 'assets/icons/handshake.svg';
import { ReactComponent as CircleCheck } from 'assets/icons/circle-check.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as SearchZoomIcon } from 'assets/icons/search-zoom.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as RotateRightIcon } from 'assets/icons/rotate-right.svg';
import { ReactComponent as HelpSupportIcon } from 'assets/icons/help_support.svg';
import { ReactComponent as UserTypeIcon } from 'assets/icons/user-type-card-icon.svg';
import { ReactComponent as MapLocationDotIcon } from 'assets/icons/map-location-dot.svg';
import { ReactComponent as FileLinesIcon } from 'assets/icons/file-lines.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as FilesIcon } from 'assets/icons/files.svg';
import { ReactComponent as CalendarDaysIcon } from 'assets/icons/calendar-days.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
import { ReactComponent as ClockRotateLeftIcon } from 'assets/icons/clock-rotate-left.svg';
import { ReactComponent as FolderMagnifyingGlassIcon } from 'assets/icons/folder-magnifying-glass.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { ReactComponent as XmarkIcon } from 'assets/icons/xmark.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

import { ReactComponent as Thunderbolt } from 'assets/icons/plans/thunderbolt.svg';
import { ReactComponent as Pictures } from 'assets/icons/plans/picture.svg';
import { ReactComponent as HouseWithOutChimney } from 'assets/icons/house-without-a-chimney.svg';
import { ReactComponent as FaTrashIcon } from 'assets/icons/faTrash.svg';
import { ReactComponent as FaMagnifyingGlass } from 'assets/icons/fa-magnifying-glass.svg';
import { ReactComponent as FaSliders } from 'assets/icons/fasliders.svg';
import { ReactComponent as FaEye } from 'assets/icons/faEye.svg';
import { ReactComponent as FaMoneyCheckDollar } from 'assets/icons/fa-money-check-dollar.svg';
import { ReactComponent as Pen } from 'assets/icons/pen.svg';
import { ReactComponent as PenWithLine } from 'assets/icons/pen-with-line.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';

import { ReactComponent as ShareBtn } from 'assets/icons/social/share-btn.svg';
import { ReactComponent as FacebookBlueIcon } from 'assets/icons/social/facebook-blue.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/social/twitter-X.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/social/telegram.svg';
import { ReactComponent as WhatsappIcon } from 'assets/icons/social/whatsapp.svg';
import { ReactComponent as EmailBlueIcon } from 'assets/icons/social/email-blue.svg';

import { ReactComponent as CreditCardIcon } from 'assets/icons/credit-card.svg';

import { ReactComponent as UpdateSearchBell } from 'assets/icons/bell-black.svg';
import { ReactComponent as UpdateSearchBlueBell } from 'assets/icons/bell-blue.svg';
import { ReactComponent as PaymentInProgress } from 'assets/icons/payment-in-progress.svg';
import { ReactComponent as PendingPayment } from 'assets/icons/pendingPayment.svg';
import { ReactComponent as WarningRedIcon } from 'assets/icons/modals/warning-red.svg';
import { ReactComponent as CancelSubscription } from 'assets/icons/cancel-subsciption.svg';
import { ReactComponent as CircleCheckSuccess } from 'assets/icons/circle-check-success.svg';

import { ReactComponent as CommissionFixed } from 'assets/icons/commisions/fixed.svg';
import { ReactComponent as CommissionPercent } from 'assets/icons/commisions/percent.svg';
import { ReactComponent as CommissionCard } from 'assets/icons/commisions/commission-card.svg';
import { ReactComponent as CommissionEdit } from 'assets/icons/commisions/commission-edit.svg';

import { ReactComponent as AvailableStatus } from 'assets/icons/statuses/available.svg';
import { ReactComponent as PendingApprovalStatus } from 'assets/icons/statuses/pendingApproval.svg';
import { ReactComponent as PendingPaymentStatus } from 'assets/icons/statuses/pendingPayment.svg';
import { ReactComponent as RejectedStatus } from 'assets/icons/statuses/rejected.svg';
import { ReactComponent as RentedStatus } from 'assets/icons/statuses/rented.svg';
import { ReactComponent as UnavailableStatus } from 'assets/icons/statuses/unavailable.svg';

import { ReactComponent as FiltersWhiteIcon } from 'assets/icons/filters/faSliders-white.svg';
import { ReactComponent as AddNewItemIcon } from 'assets/icons/filters/add-new.svg';

import { ReactComponent as StarBucksIcon } from 'assets/icons/banners/starBucks.svg';
import { ReactComponent as GiftIcon } from 'assets/icons/banners/gift.svg';

import { ReactComponent as AccessAccountKey } from 'assets/icons/profile-dropdown/access-accounts-key.svg';

import { ReactComponent as ReferralRegistration } from 'assets/icons/referrals/registration.svg';
import { ReactComponent as ReferralBonuses } from 'assets/icons/referrals/bonuses.svg';
import { ReactComponent as ReferralInvite } from 'assets/icons/referrals/send_invite.svg';
import { ReactComponent as ReferralGift } from 'assets/icons/referrals/gift.svg';
import { ReactComponent as ReferralGiftReady } from 'assets/icons/referrals/gift-ready.svg';
import { ReactComponent as ReferralCard } from 'assets/icons/referrals/referral-card.svg';
import { ReactComponent as ReferralCopied } from 'assets/icons/referrals/copied.svg';
import { ReactComponent as ReferralArrow } from 'assets/icons/referrals/arrow-right-full.svg';

import { ReactComponent as SmsImage } from 'assets/icons/auth/sms.svg';
import { ReactComponent as PresentIcon } from 'assets/icons/present.svg';

import { ReactComponent as RejectionReasonIcon } from 'assets/icons/offers/reject-offer-icon.svg';
import { ReactComponent as UpdateToNewStatus } from 'assets/icons/estates/add-new-item-dropdown.svg';

export const iconNameToIconComponent: Record<IconSVGName, FC<SVGProps<SVGSVGElement>>> = {
  loading: LoadingIcon,
  singleFamilyHouse: SingleFamilyHouseIcon,
  tinyHome: TinyHomeIcon,
  multiFamilyHouse: MultiFamilyHouseIcon,
  townhouse: TownHouseIcon,
  condo: CondoIcon,
  edit: EditIcon,
  apartment: ApartmentIcon,
  visa: VisaIcon,
  alert: AlertIcon,
  info: InfoIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  filters: FiltersIcon,
  calendar: CalendarIcon,
  delete: DeleteIcon,
  'file-lines': FileLinesIcon,
  'map-location-dot': MapLocationDotIcon,
  'circle-checked': CircleCheckedIcon,
  'circle-checked-regular': CircleCheckedRegularIcon,
  'circle-unchecked': CircleUncheckedIcon,
  download: DownloadIcon,
  bed: BedIcon,
  bath: BathIcon,
  triangle: TriangleIcon,
  tree: TreeIcon,
  dollar: DollarIcon,
  plus: PlusIcon,
  email: EmailIcon,
  apple: AppleIcon,
  facebook: FacebookIcon,
  google: GoogleIcon,
  'dots-menu': DotsMenuIcon,
  file: FileIcon,
  'times-circle': TimesCircleIcon,
  repeat: RepeatIcon,
  information: InformationIcon,
  'info-circle': InfoCircleIcon,
  danger: DangerIcon,
  'close-circle': CloseCircleIcon,
  show: ShowIcon,
  hide: HideIcon,
  house: HouseIcon,
  shape: ShapeIcon,
  handshake: HandshakeIcon,
  'circle-check': CircleCheck,
  minus: MinusIcon,
  star: StarIcon,
  'search-zoom': SearchZoomIcon,
  search: SearchIcon,
  'rotate-right': RotateRightIcon,
  thunderbolt: Thunderbolt,
  pictures: Pictures,
  'show-document': ShowDocumentIcon,
  'cancel-outlined': CancelOutLinedCustomIcon,
  'warning-round': WarningRoundIcon,
  'house-without-a-chimney': HouseWithOutChimney,
  help_support: HelpSupportIcon,
  'user-type-card-icon': UserTypeIcon,
  user: UserIcon,
  list: ListIcon,
  files: FilesIcon,
  'calendar-days': CalendarDaysIcon,
  lock: LockIcon,
  heart: HeartIcon,
  'clock-rotate-left': ClockRotateLeftIcon,
  'folder-magnifying-glass': FolderMagnifyingGlassIcon,
  xmark: XmarkIcon,
  key: KeyIcon,
  play: PlayIcon,
  clock: ClockIcon,
  faTrash: FaTrashIcon,
  'fa-magnifying-glass': FaMagnifyingGlass,
  faSliders: FaSliders,
  faEye: FaEye,
  'fa-money-check-dollar': FaMoneyCheckDollar,
  pen: Pen,
  check: Check,
  shareBtn: ShareBtn,
  facebookBlue: FacebookBlueIcon,
  'twitter-X': TwitterIcon,
  telegram: TelegramIcon,
  whatsapp: WhatsappIcon,
  'email-blue': EmailBlueIcon,
  'credit-card': CreditCardIcon,
  'bell-black': UpdateSearchBell,
  'bell-blue': UpdateSearchBlueBell,
  'payment-in-progress': PaymentInProgress,
  pendingPayment: PendingPayment,
  'warning-red': WarningRedIcon,
  'cancel-subsciption': CancelSubscription,
  'commission-fixed': CommissionFixed,
  'commission-percent': CommissionPercent,
  'available-status': AvailableStatus,
  'pendingApproval-status': PendingApprovalStatus,
  'pendingPayment-status': PendingPaymentStatus,
  'rejected-status': RejectedStatus,
  'rented-status': RentedStatus,
  'unavailable-status': UnavailableStatus,
  'filters-white': FiltersWhiteIcon,
  'add-new-item': AddNewItemIcon,
  'commision-card': CommissionCard,
  'commision-edit': CommissionEdit,
  starBucks: StarBucksIcon,
  gift: GiftIcon,
  'access-accounts-key': AccessAccountKey,
  'referral-invite': ReferralInvite,
  'referral-registation': ReferralRegistration,
  'referral-bonuses': ReferralBonuses,
  'referral-gift': ReferralGift,
  'referral-gift-ready': ReferralGiftReady,
  'referral-card': ReferralCard,
  'circle-check-success': CircleCheckSuccess,
  'referral-copied': ReferralCopied,
  'arrow-right-full': ReferralArrow,
  sms: SmsImage,
  present: PresentIcon,
  'reject-offer-icon': RejectionReasonIcon,
  'add-new-item-dropdown': UpdateToNewStatus,
  'pen-with-line': PenWithLine,
  'dollar-solid-circle': DollarSolidCircle,
};
