import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { BackendError } from 'common/types/errors/backendError';
import { ScreeningServicePlanPayment, ScreeningServicePlanPaymentPayload } from 'common/types/services/api/tenant-screening';
import { requestBackgroundCheckPayment } from 'services/api/payment-api.service';

export function useRequestBackgroundCheckPayment(options?: UseMutationOptions<ScreeningServicePlanPayment, AxiosError<BackendError>, ScreeningServicePlanPaymentPayload, unknown>) {
  return useMutation({
    ...options,
    mutationFn: requestBackgroundCheckPayment,
  });
}
