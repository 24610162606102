import { openNotificationModal } from 'store/use-notification-modal-store';
import { useUserProfileValidation } from './query';
import { AuthRole, NotificationType } from 'common/enums/enums';
import { useHasRoles } from './use-has-roles.hook';
import { NotificationModalState } from 'common/types/types';

type Arguments = {
  modalProps: {
    actionText: string;
    onClose?: NotificationModalState['onClose'];
  };
};

const useUnverifiedProfileActionGuard = (args: Arguments) => {
  const { modalProps } = args;
  const { data } = useUserProfileValidation();
  const isLandlord = useHasRoles([AuthRole.Landlord]);

  const getUserHasVerification = () => {
    if (isLandlord && !Boolean(data?.isIdentityEnabled)) {
      return Boolean(data?.isProfileValid);
    }

    return Boolean(data?.isProfileValid && data.isIdentityValid);
  };

  const openModal = () => {
    openNotificationModal({
      type: NotificationType.WARNING,
      title: 'Additional information required',
      description: `You are unable ${modalProps.actionText} because your profile is incomplete. To ensure a smooth and secure experience, please fill out your profile with all the required information`,
      onClose: modalProps.onClose,
    });
  };

  const withActionGuard = (action: () => void) => {
    const isUserVerified = getUserHasVerification();
    isUserVerified ? action() : openModal();
  };

  return {
    withActionGuard,
  };
};

export { useUnverifiedProfileActionGuard };
